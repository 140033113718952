import type {
  Configuration,
  SGDFUserDetails} from "@sgdf/client";
import {
  AuthApi,
  UsersApi} from "@sgdf/client";
import { getConfiguration } from "../configuration";
import { meetingService } from "./meeting.service";
import { DateTime } from "luxon";
import { jwtDecode } from "jwt-decode";

let instance: AuthClient | undefined;

export const authenticationService = {
  getInstance: () => {
    if (instance == undefined) {
      instance = authenticationService.createInstance();
    }

    return instance;
  },

  createInstance: () => {
    const accessToken = localStorage.getItem("access_token") ?? undefined;
    const conf = getConfiguration(accessToken);
    instance = new AuthClient(conf);
    return instance;
  },

  login: (user: { username: string; password: string }) => {
    return authenticationService.getInstance().login(user);
  },

  logout: () => {
    return authenticationService.getInstance().logout();
  },

  resetPassword: (email: string) => {
    return authenticationService.getInstance().resetPassword(email);
  },
  resetPasswordConfirm: (
    uid: string,
    token: string,
    newPassword: string,
    reNewPassword: string,
  ) => {
    return authenticationService
      .getInstance()
      .resetPasswordConfirm(uid, token, newPassword, reNewPassword);
  },

  scanCode: ({
    qrCodeToken,
    meetingId,
  }: {
    qrCodeToken: string;
    meetingId: string;
  }) => {
    return authenticationService
      .getInstance()
      .scanCode({ qrCodeToken, meetingId });
  },

  forceGetUser: async () => {
    if (!localStorage.getItem("access_token")) {
      return null;
    }
    try {
      const a = await authenticationService.getInstance().me();

      localStorage.setItem("current_user", JSON.stringify(a));
      localStorage.setItem("last_user_update", DateTime.now().toISO() ?? "");

      return a;
    } catch (e) {
      return null;
    }
  },

  refreshToken: async () => {
    return await authenticationService.getInstance().refreshTokens();
  },

  getUser: async (): Promise<SGDFUserDetails | null> => {
    const localUser = localStorage.getItem("current_user");
    let old = null;

    if (localUser && localUser != "undefined") {
      old = JSON.parse(localUser);
    } else {
      old = null;
    }

    const lastUserUpdate = localStorage.getItem("last_user_update");
    const old_lu = lastUserUpdate
      ? DateTime.fromISO(lastUserUpdate)
      : DateTime.now().plus({ days: -8 });

    if (old === null || old_lu < DateTime.now().plus({ hours: -12 })) {
      return await authenticationService.forceGetUser();
    } else {
      return old;
    }
  },
};

export class AuthClient {
  auth: AuthApi;
  users: UsersApi;

  constructor(conf: Configuration) {
    this.auth = new AuthApi(conf);
    this.users = new UsersApi(conf);
  }

  refreshTokens = async () => {
    const refresh_token = localStorage.getItem("refresh_token");
    if (!refresh_token) {
      return null;
    }

    try {
      const req = await this.auth.authJwtRefreshCreate({
        tokenRefresh: {
          refresh: refresh_token,
        },
      });
      console.log("refreshed tokens", req);

      this.complete({
        access_token: req.access,
        refresh_token: req.refresh ?? refresh_token,
      });

      return req;
    } catch (e) {
      console.log("error refreshing tokens", e);
      return null;
    }

  };

  login = async (user: { username: string; password: string }) => {
    const resp = await this.auth.authJwtCreateCreate({
      tokenObtainPairEmail: {
        username_or_email: user.username,
        password: user.password,
      },
    });

    const { access, refresh } = resp;
    if (access && refresh) {
      this.complete({
        access_token: access,
        refresh_token: refresh,
      });
    } else {
      return Promise.reject({
        non_field_errors: ["message.user_not_authorized_backoffice"],
      });
    }
  };

  resetPassword = async (email: string) => {
    return await this.auth.authUsersResetPasswordCreate({
      sendEmailReset: {
        email,
      },
    });
  };
  resetPasswordConfirm = async (
    uid: string,
    token: string,
    newPassword: string,
    reNewPassword: string,
  ) => {
    return await this.auth.authUsersResetPasswordConfirmCreate({
      passwordResetConfirmRetype: {
        uid,
        token,
        new_password: newPassword,
        re_new_password: reNewPassword,
      },
    });
  };

  complete = ({
    access_token,
    refresh_token,
  }: {
    access_token: string;
    refresh_token: string;
  }) => {
    localStorage.setItem("access_token", access_token);
    localStorage.setItem("refresh_token", refresh_token);

    meetingService.createInstance();
    authenticationService.createInstance();
  };

  scanCode = ({
    qrCodeToken,
    meetingId,
  }: {
    qrCodeToken: string;
    meetingId: string;
  }) => {
    localStorage.setItem(`qrcode_token:${meetingId}`, qrCodeToken);
  };

  logout = (meetingId?: string) => {
    // remove user from local storage to log user out
    localStorage.removeItem("current_user");
    localStorage.removeItem("last_user_update");
    localStorage.removeItem("access_token");
    localStorage.removeItem("refresh_token");

    if (meetingId) {
      localStorage.removeItem(`qrcode_token:${meetingId}`);
    }

    localStorage.removeItem("current_meeting");
  };

  me = async () => {
    try {
      const me = await this.users.usersMeRetrieve({ uuid: "" });
      return me;
    } catch (e) {
      return null;
    }
  };
}

export const tokenManager = {
  decodeToken(token: string) {
    return jwtDecode<{ exp?: number }>(token);
  },
  getTokenExpirationDate(t: string) {
    const decodedToken = this.decodeToken(t);

    if (decodedToken && "exp" in decodedToken && decodedToken.exp) {
      return new Date(decodedToken.exp * 1000);
    }

    return null;
  },
  isTokenExpired(token: string, delay: number) {
    if (!token || "" === token) return true;
    const expDate = this.getTokenExpirationDate(token);

    if (!expDate) return true;

    const currentDate = new Date();
    const milliseconds = delay * 1000; // 10 seconds = 10000 milliseconds
    const currentDateWithDelay = new Date(currentDate.getTime() + milliseconds);

    return currentDateWithDelay > expDate;
  },
};

// see: https://www.bezkoder.com/vue-3-authentication-jwt/
