/* tslint:disable */
/* eslint-disable */
/**
 * Digiform API
 * Digiform API pour les SGDF
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { OwnMeetingParticipant } from './OwnMeetingParticipant';
import {
    OwnMeetingParticipantFromJSON,
    OwnMeetingParticipantFromJSONTyped,
    OwnMeetingParticipantToJSON,
    OwnMeetingParticipantToJSONTyped,
} from './OwnMeetingParticipant';
import type { NotNestedDiploma } from './NotNestedDiploma';
import {
    NotNestedDiplomaFromJSON,
    NotNestedDiplomaFromJSONTyped,
    NotNestedDiplomaToJSON,
    NotNestedDiplomaToJSONTyped,
} from './NotNestedDiploma';
import type { GenderEnum } from './GenderEnum';
import {
    GenderEnumFromJSON,
    GenderEnumFromJSONTyped,
    GenderEnumToJSON,
    GenderEnumToJSONTyped,
} from './GenderEnum';

/**
 * This Serializer allow us to get access to the current user
 * @export
 * @interface SGDFUserDetails
 */
export interface SGDFUserDetails {
    /**
     * 
     * @type {string}
     * @memberof SGDFUserDetails
     */
    readonly id: string;
    /**
     * 
     * @type {string}
     * @memberof SGDFUserDetails
     */
    readonly email: string;
    /**
     * Requis. 150 caractères maximum. Uniquement des lettres, nombres et les caractères « @ », « . », « + », « - » et « _ ».
     * @type {string}
     * @memberof SGDFUserDetails
     */
    readonly username: string;
    /**
     * 
     * @type {string}
     * @memberof SGDFUserDetails
     */
    readonly adherent_id: string;
    /**
     * 
     * @type {string}
     * @memberof SGDFUserDetails
     */
    readonly phone: string;
    /**
     * 
     * @type {string}
     * @memberof SGDFUserDetails
     */
    readonly first_name: string;
    /**
     * 
     * @type {string}
     * @memberof SGDFUserDetails
     */
    readonly last_name: string;
    /**
     * 
     * @type {string}
     * @memberof SGDFUserDetails
     */
    readonly usage_first_name: string | null;
    /**
     * 
     * @type {string}
     * @memberof SGDFUserDetails
     */
    readonly usage_last_name: string | null;
    /**
     * 
     * @type {GenderEnum}
     * @memberof SGDFUserDetails
     */
    readonly gender: GenderEnum;
    /**
     * 
     * @type {Date}
     * @memberof SGDFUserDetails
     */
    readonly birthdate: Date | null;
    /**
     * Précise que l’utilisateur possède toutes les permissions sans les assigner explicitement.
     * @type {boolean}
     * @memberof SGDFUserDetails
     */
    readonly is_superuser: boolean;
    /**
     * 
     * @type {string}
     * @memberof SGDFUserDetails
     */
    structure: string | null;
    /**
     * 
     * @type {string}
     * @memberof SGDFUserDetails
     */
    fonction: string | null;
    /**
     * 
     * @type {string}
     * @memberof SGDFUserDetails
     */
    fonction_code: string | null;
    /**
     * 
     * @type {Array<OwnMeetingParticipant>}
     * @memberof SGDFUserDetails
     */
    readonly participations: Array<OwnMeetingParticipant>;
    /**
     * Is true when Déontologie have been validated and accepted
     * @type {boolean}
     * @memberof SGDFUserDetails
     */
    readonly is_deontologie_validated: boolean;
    /**
     * 
     * @type {Array<NotNestedDiploma>}
     * @memberof SGDFUserDetails
     */
    diplomas: Array<NotNestedDiploma>;
    /**
     * 
     * @type {string}
     * @memberof SGDFUserDetails
     */
    readonly avatar_url: string;
    /**
     * 
     * @type {boolean}
     * @memberof SGDFUserDetails
     */
    readonly avatar_available: boolean;
    /**
     * 
     * @type {string}
     * @memberof SGDFUserDetails
     */
    readonly document_signature_url: string;
    /**
     * 
     * @type {boolean}
     * @memberof SGDFUserDetails
     */
    readonly document_signature_available: boolean;
    /**
     * 
     * @type {Date}
     * @memberof SGDFUserDetails
     */
    readonly last_sync: Date | null;
}



/**
 * Check if a given object implements the SGDFUserDetails interface.
 */
export function instanceOfSGDFUserDetails(value: object): value is SGDFUserDetails {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('email' in value) || value['email'] === undefined) return false;
    if (!('username' in value) || value['username'] === undefined) return false;
    if (!('adherent_id' in value) || value['adherent_id'] === undefined) return false;
    if (!('phone' in value) || value['phone'] === undefined) return false;
    if (!('first_name' in value) || value['first_name'] === undefined) return false;
    if (!('last_name' in value) || value['last_name'] === undefined) return false;
    if (!('usage_first_name' in value) || value['usage_first_name'] === undefined) return false;
    if (!('usage_last_name' in value) || value['usage_last_name'] === undefined) return false;
    if (!('gender' in value) || value['gender'] === undefined) return false;
    if (!('birthdate' in value) || value['birthdate'] === undefined) return false;
    if (!('is_superuser' in value) || value['is_superuser'] === undefined) return false;
    if (!('structure' in value) || value['structure'] === undefined) return false;
    if (!('fonction' in value) || value['fonction'] === undefined) return false;
    if (!('fonction_code' in value) || value['fonction_code'] === undefined) return false;
    if (!('participations' in value) || value['participations'] === undefined) return false;
    if (!('is_deontologie_validated' in value) || value['is_deontologie_validated'] === undefined) return false;
    if (!('diplomas' in value) || value['diplomas'] === undefined) return false;
    if (!('avatar_url' in value) || value['avatar_url'] === undefined) return false;
    if (!('avatar_available' in value) || value['avatar_available'] === undefined) return false;
    if (!('document_signature_url' in value) || value['document_signature_url'] === undefined) return false;
    if (!('document_signature_available' in value) || value['document_signature_available'] === undefined) return false;
    if (!('last_sync' in value) || value['last_sync'] === undefined) return false;
    return true;
}

export function SGDFUserDetailsFromJSON(json: any): SGDFUserDetails {
    return SGDFUserDetailsFromJSONTyped(json, false);
}

export function SGDFUserDetailsFromJSONTyped(json: any, ignoreDiscriminator: boolean): SGDFUserDetails {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'email': json['email'],
        'username': json['username'],
        'adherent_id': json['adherent_id'],
        'phone': json['phone'],
        'first_name': json['first_name'],
        'last_name': json['last_name'],
        'usage_first_name': json['usage_first_name'],
        'usage_last_name': json['usage_last_name'],
        'gender': GenderEnumFromJSON(json['gender']),
        'birthdate': (json['birthdate'] == null ? null : new Date(json['birthdate'])),
        'is_superuser': json['is_superuser'],
        'structure': json['structure'],
        'fonction': json['fonction'],
        'fonction_code': json['fonction_code'],
        'participations': ((json['participations'] as Array<any>).map(OwnMeetingParticipantFromJSON)),
        'is_deontologie_validated': json['is_deontologie_validated'],
        'diplomas': ((json['diplomas'] as Array<any>).map(NotNestedDiplomaFromJSON)),
        'avatar_url': json['avatar_url'],
        'avatar_available': json['avatar_available'],
        'document_signature_url': json['document_signature_url'],
        'document_signature_available': json['document_signature_available'],
        'last_sync': (json['last_sync'] == null ? null : new Date(json['last_sync'])),
    };
}

export function SGDFUserDetailsToJSON(json: any): SGDFUserDetails {
    return SGDFUserDetailsToJSONTyped(json, false);
}

export function SGDFUserDetailsToJSONTyped(value?: Omit<SGDFUserDetails, 'id'|'email'|'username'|'adherent_id'|'phone'|'first_name'|'last_name'|'usage_first_name'|'usage_last_name'|'gender'|'birthdate'|'is_superuser'|'participations'|'is_deontologie_validated'|'avatar_url'|'avatar_available'|'document_signature_url'|'document_signature_available'|'last_sync'> | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'structure': value['structure'],
        'fonction': value['fonction'],
        'fonction_code': value['fonction_code'],
        'diplomas': ((value['diplomas'] as Array<any>).map(NotNestedDiplomaToJSON)),
    };
}

