/* tslint:disable */
/* eslint-disable */
/**
 * Digiform API
 * Digiform API pour les SGDF
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * * `not_sync` - Non synchronisé
 * * `error_unknown` - Erreur : Raison inconnue
 * * `nbafa_not_found` - Erreur : Numéro de candidat BAFA/BAFD non trouvé
 * * `names_not_found` - Erreur : Dossier non trouvé par Prénom + Nom + Date de naissance
 * * `incorrect_inscription_state` - Erreur : Le candidat existe mais cursus ou état inscriptionnon correct (voir table des règles d’affectation
 * * `opinion_too_long` - Erreur : l'avis de stage est trop long
 * * `opinion_too_short` - Erreur : l'avis de stage est trop court
 * * `session_not_set` - Error: le statut de session est indéfini
 * * `found_not_added` - Candidat trouvé - Non synchronisé
 * * `incoherent_names` - Attention : des données du dossier (Nom, Prénom, ou date denaissance) sont incohérente entre elles
 * * `synchronized` - Candidat synchronisé
 * * `synchronized_verified` - Candidat synchronisé et informations vérifiées
 * @export
 */
export const TagadaCandidateStatusEnum = {
    NotSync: 'not_sync',
    ErrorUnknown: 'error_unknown',
    NbafaNotFound: 'nbafa_not_found',
    NamesNotFound: 'names_not_found',
    IncorrectInscriptionState: 'incorrect_inscription_state',
    OpinionTooLong: 'opinion_too_long',
    OpinionTooShort: 'opinion_too_short',
    SessionNotSet: 'session_not_set',
    FoundNotAdded: 'found_not_added',
    IncoherentNames: 'incoherent_names',
    Synchronized: 'synchronized',
    SynchronizedVerified: 'synchronized_verified'
} as const;
export type TagadaCandidateStatusEnum = typeof TagadaCandidateStatusEnum[keyof typeof TagadaCandidateStatusEnum];


export function instanceOfTagadaCandidateStatusEnum(value: any): boolean {
    for (const key in TagadaCandidateStatusEnum) {
        if (Object.prototype.hasOwnProperty.call(TagadaCandidateStatusEnum, key)) {
            if (TagadaCandidateStatusEnum[key as keyof typeof TagadaCandidateStatusEnum] === value) {
                return true;
            }
        }
    }
    return false;
}

export function TagadaCandidateStatusEnumFromJSON(json: any): TagadaCandidateStatusEnum {
    return TagadaCandidateStatusEnumFromJSONTyped(json, false);
}

export function TagadaCandidateStatusEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): TagadaCandidateStatusEnum {
    return json as TagadaCandidateStatusEnum;
}

export function TagadaCandidateStatusEnumToJSON(value?: TagadaCandidateStatusEnum | null): any {
    return value as any;
}

export function TagadaCandidateStatusEnumToJSONTyped(value: any, ignoreDiscriminator: boolean): TagadaCandidateStatusEnum {
    return value as TagadaCandidateStatusEnum;
}

