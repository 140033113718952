/* tslint:disable */
/* eslint-disable */
/**
 * Digiform API
 * Digiform API pour les SGDF
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface TagadaCandidate
 */
export interface TagadaCandidate {
    /**
     * 
     * @type {string}
     * @memberof TagadaCandidate
     */
    numero_dossier?: string;
    /**
     * 
     * @type {string}
     * @memberof TagadaCandidate
     */
    nom?: string;
    /**
     * 
     * @type {string}
     * @memberof TagadaCandidate
     */
    prenom?: string;
    /**
     * 
     * @type {string}
     * @memberof TagadaCandidate
     */
    date_naissance?: string;
    /**
     * 
     * @type {string}
     * @memberof TagadaCandidate
     */
    lieu_naissance?: string;
    /**
     * 
     * @type {string}
     * @memberof TagadaCandidate
     */
    adresse?: string;
    /**
     * 
     * @type {string}
     * @memberof TagadaCandidate
     */
    telephone?: string;
    /**
     * 
     * @type {string}
     * @memberof TagadaCandidate
     */
    mobile?: string;
    /**
     * 
     * @type {string}
     * @memberof TagadaCandidate
     */
    courriel?: string;
    /**
     * 
     * @type {string}
     * @memberof TagadaCandidate
     */
    code_diplome?: string;
    /**
     * 
     * @type {string}
     * @memberof TagadaCandidate
     */
    libelle_diplome?: string;
    /**
     * 
     * @type {string}
     * @memberof TagadaCandidate
     */
    code_etat_dossier?: string;
    /**
     * 
     * @type {string}
     * @memberof TagadaCandidate
     */
    libelle_etat_dossier?: string;
    /**
     * 
     * @type {string}
     * @memberof TagadaCandidate
     */
    code_erreur?: string;
    /**
     * 
     * @type {string}
     * @memberof TagadaCandidate
     */
    libelle_erreur?: string;
}

/**
 * Check if a given object implements the TagadaCandidate interface.
 */
export function instanceOfTagadaCandidate(value: object): value is TagadaCandidate {
    return true;
}

export function TagadaCandidateFromJSON(json: any): TagadaCandidate {
    return TagadaCandidateFromJSONTyped(json, false);
}

export function TagadaCandidateFromJSONTyped(json: any, ignoreDiscriminator: boolean): TagadaCandidate {
    if (json == null) {
        return json;
    }
    return {
        
        'numero_dossier': json['numeroDossier'] == null ? undefined : json['numeroDossier'],
        'nom': json['nom'] == null ? undefined : json['nom'],
        'prenom': json['prenom'] == null ? undefined : json['prenom'],
        'date_naissance': json['dateNaissance'] == null ? undefined : json['dateNaissance'],
        'lieu_naissance': json['lieuNaissance'] == null ? undefined : json['lieuNaissance'],
        'adresse': json['adresse'] == null ? undefined : json['adresse'],
        'telephone': json['telephone'] == null ? undefined : json['telephone'],
        'mobile': json['mobile'] == null ? undefined : json['mobile'],
        'courriel': json['courriel'] == null ? undefined : json['courriel'],
        'code_diplome': json['codeDiplome'] == null ? undefined : json['codeDiplome'],
        'libelle_diplome': json['libelleDiplome'] == null ? undefined : json['libelleDiplome'],
        'code_etat_dossier': json['codeEtatDossier'] == null ? undefined : json['codeEtatDossier'],
        'libelle_etat_dossier': json['libelleEtatDossier'] == null ? undefined : json['libelleEtatDossier'],
        'code_erreur': json['codeErreur'] == null ? undefined : json['codeErreur'],
        'libelle_erreur': json['libelleErreur'] == null ? undefined : json['libelleErreur'],
    };
}

export function TagadaCandidateToJSON(json: any): TagadaCandidate {
    return TagadaCandidateToJSONTyped(json, false);
}

export function TagadaCandidateToJSONTyped(value?: TagadaCandidate | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'numeroDossier': value['numero_dossier'],
        'nom': value['nom'],
        'prenom': value['prenom'],
        'dateNaissance': value['date_naissance'],
        'lieuNaissance': value['lieu_naissance'],
        'adresse': value['adresse'],
        'telephone': value['telephone'],
        'mobile': value['mobile'],
        'courriel': value['courriel'],
        'codeDiplome': value['code_diplome'],
        'libelleDiplome': value['libelle_diplome'],
        'codeEtatDossier': value['code_etat_dossier'],
        'libelleEtatDossier': value['libelle_etat_dossier'],
        'codeErreur': value['code_erreur'],
        'libelleErreur': value['libelle_erreur'],
    };
}

