/* tslint:disable */
/* eslint-disable */
/**
 * Digiform API
 * Digiform API pour les SGDF
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AnonymizeMeeting
 */
export interface AnonymizeMeeting {
    /**
     * 
     * @type {string}
     * @memberof AnonymizeMeeting
     */
    message: string;
}

/**
 * Check if a given object implements the AnonymizeMeeting interface.
 */
export function instanceOfAnonymizeMeeting(value: object): value is AnonymizeMeeting {
    if (!('message' in value) || value['message'] === undefined) return false;
    return true;
}

export function AnonymizeMeetingFromJSON(json: any): AnonymizeMeeting {
    return AnonymizeMeetingFromJSONTyped(json, false);
}

export function AnonymizeMeetingFromJSONTyped(json: any, ignoreDiscriminator: boolean): AnonymizeMeeting {
    if (json == null) {
        return json;
    }
    return {
        
        'message': json['message'],
    };
}

export function AnonymizeMeetingToJSON(json: any): AnonymizeMeeting {
    return AnonymizeMeetingToJSONTyped(json, false);
}

export function AnonymizeMeetingToJSONTyped(value?: AnonymizeMeeting | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'message': value['message'],
    };
}

