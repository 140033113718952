/* tslint:disable */
/* eslint-disable */
/**
 * Digiform API
 * Digiform API pour les SGDF
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * * `untested` - Synchronisation Tagada non-testé
 * * `missing_id` - Le numéro d'habilitation est manquant
 * * `incorrect_id` - Le numéro d'habilitation semble incorrect : stage non-trouvé sur l'extranet BAFA/BAFD
 * * `readonly_session` - La session ne peut pas être édité
 * * `candidates_in_error` - La synchronisation de certains participants a échoué
 * * `error_unknown` - Erreur inconnue
 * * `ok` - Connexion Tagada fonctionnelle
 * @export
 */
export const TagadaStatusEnum = {
    Untested: 'untested',
    MissingId: 'missing_id',
    IncorrectId: 'incorrect_id',
    ReadonlySession: 'readonly_session',
    CandidatesInError: 'candidates_in_error',
    ErrorUnknown: 'error_unknown',
    Ok: 'ok'
} as const;
export type TagadaStatusEnum = typeof TagadaStatusEnum[keyof typeof TagadaStatusEnum];


export function instanceOfTagadaStatusEnum(value: any): boolean {
    for (const key in TagadaStatusEnum) {
        if (Object.prototype.hasOwnProperty.call(TagadaStatusEnum, key)) {
            if (TagadaStatusEnum[key as keyof typeof TagadaStatusEnum] === value) {
                return true;
            }
        }
    }
    return false;
}

export function TagadaStatusEnumFromJSON(json: any): TagadaStatusEnum {
    return TagadaStatusEnumFromJSONTyped(json, false);
}

export function TagadaStatusEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): TagadaStatusEnum {
    return json as TagadaStatusEnum;
}

export function TagadaStatusEnumToJSON(value?: TagadaStatusEnum | null): any {
    return value as any;
}

export function TagadaStatusEnumToJSONTyped(value: any, ignoreDiscriminator: boolean): TagadaStatusEnum {
    return value as TagadaStatusEnum;
}

