/* tslint:disable */
/* eslint-disable */
/**
 * Digiform API
 * Digiform API pour les SGDF
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  AnonymizeMeeting,
  Meeting,
  MeetingPublic,
  MeetingType,
  NotNestedMeetingRole,
  OwnMeetingParticipant,
  OwnMeetingUpdateParticipant,
  PaginatedMeetingList,
  PaginatedMeetingPublicList,
  PaginatedMeetingTypeList,
  PaginatedNotNestedMeetingRoleList,
  PatchedOwnMeetingUpdateParticipant,
  SyncFromIntranet,
  TagadaCandidate,
  UserAccessRights,
} from '../models/index';
import {
    AnonymizeMeetingFromJSON,
    AnonymizeMeetingToJSON,
    MeetingFromJSON,
    MeetingToJSON,
    MeetingPublicFromJSON,
    MeetingPublicToJSON,
    MeetingTypeFromJSON,
    MeetingTypeToJSON,
    NotNestedMeetingRoleFromJSON,
    NotNestedMeetingRoleToJSON,
    OwnMeetingParticipantFromJSON,
    OwnMeetingParticipantToJSON,
    OwnMeetingUpdateParticipantFromJSON,
    OwnMeetingUpdateParticipantToJSON,
    PaginatedMeetingListFromJSON,
    PaginatedMeetingListToJSON,
    PaginatedMeetingPublicListFromJSON,
    PaginatedMeetingPublicListToJSON,
    PaginatedMeetingTypeListFromJSON,
    PaginatedMeetingTypeListToJSON,
    PaginatedNotNestedMeetingRoleListFromJSON,
    PaginatedNotNestedMeetingRoleListToJSON,
    PatchedOwnMeetingUpdateParticipantFromJSON,
    PatchedOwnMeetingUpdateParticipantToJSON,
    SyncFromIntranetFromJSON,
    SyncFromIntranetToJSON,
    TagadaCandidateFromJSON,
    TagadaCandidateToJSON,
    UserAccessRightsFromJSON,
    UserAccessRightsToJSON,
} from '../models/index';

export interface MeetingRolesListRequest {
    limit?: number;
    offset?: number;
    ordering?: string;
    search?: string;
}

export interface MeetingRolesRetrieveRequest {
    id: string;
}

export interface MeetingTypesListRequest {
    ordering?: string;
    page?: number;
    pageSize?: number;
    search?: string;
}

export interface MeetingTypesRetrieveRequest {
    id: string;
}

export interface MeetingsListRequest {
    centreRessource?: string;
    endDate?: Date;
    includePastYears?: boolean;
    limit?: number;
    meetingTypeIdIn?: Array<string>;
    offset?: number;
    opinionStatus?: string;
    ordering?: string;
    search?: string;
    startDate?: Date;
    withHasOpinions?: boolean;
}

export interface MeetingsMyParticipationPartialUpdateRequest {
    uuid: string;
    patchedOwnMeetingUpdateParticipant?: PatchedOwnMeetingUpdateParticipant;
}

export interface MeetingsMyParticipationRetrieveRequest {
    uuid: string;
}

export interface MeetingsMyParticipationUpdateRequest {
    uuid: string;
    ownMeetingUpdateParticipant?: OwnMeetingUpdateParticipant;
}

export interface MeetingsPublicAccessRightsRetrieveRequest {
    uuid: string;
}

export interface MeetingsPublicListRequest {
    centreRessource?: string;
    endDate?: Date;
    includePastYears?: boolean;
    limit?: number;
    meetingTypeIdIn?: Array<string>;
    offset?: number;
    opinionStatus?: string;
    ordering?: string;
    search?: string;
    startDate?: Date;
    withHasOpinions?: boolean;
}

export interface MeetingsPublicRetrieveRequest {
    uuid: string;
}

export interface MeetingsRequestAnonymizationPartialUpdateRequest {
    uuid: string;
    cancelAnonymization?: boolean;
}

export interface MeetingsResetParticipantsAvatarDestroyRequest {
    uuid: string;
}

export interface MeetingsResetParticipantsSignatureDestroyRequest {
    uuid: string;
}

export interface MeetingsRetrieveRequest {
    uuid: string;
}

export interface MeetingsRetrieveTagadaCandidateRetrieveRequest {
    uuid: string;
    candidate?: string;
}

export interface MeetingsSyncAvisTagadaCreateRequest {
    uuid: string;
}

export interface MeetingsSyncFromIntranetCreateRequest {
    uuid: string;
}

export interface MeetingsSyncParticipantsTagadaCreateRequest {
    uuid: string;
}

/**
 * 
 */
export class MeetingsApi extends runtime.BaseAPI {

    /**
     * Un \"MeetingRole\" correspond à un rôle dans une action de formation (Directeur, Participant, ...)
     */
    async meetingRolesListRaw(requestParameters: MeetingRolesListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PaginatedNotNestedMeetingRoleList>> {
        const queryParameters: any = {};

        if (requestParameters['limit'] != null) {
            queryParameters['limit'] = requestParameters['limit'];
        }

        if (requestParameters['offset'] != null) {
            queryParameters['offset'] = requestParameters['offset'];
        }

        if (requestParameters['ordering'] != null) {
            queryParameters['ordering'] = requestParameters['ordering'];
        }

        if (requestParameters['search'] != null) {
            queryParameters['search'] = requestParameters['search'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // qrCodeTokenAuth authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // jwtAuth authentication
        }

        const response = await this.request({
            path: `/api/meeting-roles/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PaginatedNotNestedMeetingRoleListFromJSON(jsonValue));
    }

    /**
     * Un \"MeetingRole\" correspond à un rôle dans une action de formation (Directeur, Participant, ...)
     */
    async meetingRolesList(requestParameters: MeetingRolesListRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PaginatedNotNestedMeetingRoleList> {
        const response = await this.meetingRolesListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Un \"MeetingRole\" correspond à un rôle dans une action de formation (Directeur, Participant, ...)
     */
    async meetingRolesRetrieveRaw(requestParameters: MeetingRolesRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<NotNestedMeetingRole>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling meetingRolesRetrieve().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // qrCodeTokenAuth authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // jwtAuth authentication
        }

        const response = await this.request({
            path: `/api/meeting-roles/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => NotNestedMeetingRoleFromJSON(jsonValue));
    }

    /**
     * Un \"MeetingRole\" correspond à un rôle dans une action de formation (Directeur, Participant, ...)
     */
    async meetingRolesRetrieve(requestParameters: MeetingRolesRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<NotNestedMeetingRole> {
        const response = await this.meetingRolesRetrieveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Un \"MeetingType\" correspond à un type d\'action de formation ou d\'événement : TECH, APPRO, ...
     */
    async meetingTypesListRaw(requestParameters: MeetingTypesListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PaginatedMeetingTypeList>> {
        const queryParameters: any = {};

        if (requestParameters['ordering'] != null) {
            queryParameters['ordering'] = requestParameters['ordering'];
        }

        if (requestParameters['page'] != null) {
            queryParameters['page'] = requestParameters['page'];
        }

        if (requestParameters['pageSize'] != null) {
            queryParameters['page_size'] = requestParameters['pageSize'];
        }

        if (requestParameters['search'] != null) {
            queryParameters['search'] = requestParameters['search'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // qrCodeTokenAuth authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // jwtAuth authentication
        }

        const response = await this.request({
            path: `/api/meeting-types/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PaginatedMeetingTypeListFromJSON(jsonValue));
    }

    /**
     * Un \"MeetingType\" correspond à un type d\'action de formation ou d\'événement : TECH, APPRO, ...
     */
    async meetingTypesList(requestParameters: MeetingTypesListRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PaginatedMeetingTypeList> {
        const response = await this.meetingTypesListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Un \"MeetingType\" correspond à un type d\'action de formation ou d\'événement : TECH, APPRO, ...
     */
    async meetingTypesRetrieveRaw(requestParameters: MeetingTypesRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MeetingType>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling meetingTypesRetrieve().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // qrCodeTokenAuth authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // jwtAuth authentication
        }

        const response = await this.request({
            path: `/api/meeting-types/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MeetingTypeFromJSON(jsonValue));
    }

    /**
     * Un \"MeetingType\" correspond à un type d\'action de formation ou d\'événement : TECH, APPRO, ...
     */
    async meetingTypesRetrieve(requestParameters: MeetingTypesRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MeetingType> {
        const response = await this.meetingTypesRetrieveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Liste seulement les stages où je suis participant
     */
    async meetingsListRaw(requestParameters: MeetingsListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PaginatedMeetingList>> {
        const queryParameters: any = {};

        if (requestParameters['centreRessource'] != null) {
            queryParameters['centre_ressource'] = requestParameters['centreRessource'];
        }

        if (requestParameters['endDate'] != null) {
            queryParameters['end_date'] = (requestParameters['endDate'] as any).toISOString().substring(0,10);
        }

        if (requestParameters['includePastYears'] != null) {
            queryParameters['include_past_years'] = requestParameters['includePastYears'];
        }

        if (requestParameters['limit'] != null) {
            queryParameters['limit'] = requestParameters['limit'];
        }

        if (requestParameters['meetingTypeIdIn'] != null) {
            queryParameters['meeting_type__id__in'] = requestParameters['meetingTypeIdIn']!.join(runtime.COLLECTION_FORMATS["csv"]);
        }

        if (requestParameters['offset'] != null) {
            queryParameters['offset'] = requestParameters['offset'];
        }

        if (requestParameters['opinionStatus'] != null) {
            queryParameters['opinion_status'] = requestParameters['opinionStatus'];
        }

        if (requestParameters['ordering'] != null) {
            queryParameters['ordering'] = requestParameters['ordering'];
        }

        if (requestParameters['search'] != null) {
            queryParameters['search'] = requestParameters['search'];
        }

        if (requestParameters['startDate'] != null) {
            queryParameters['start_date'] = (requestParameters['startDate'] as any).toISOString().substring(0,10);
        }

        if (requestParameters['withHasOpinions'] != null) {
            queryParameters['with_has_opinions'] = requestParameters['withHasOpinions'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // qrCodeTokenAuth authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // jwtAuth authentication
        }

        const response = await this.request({
            path: `/api/meetings/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PaginatedMeetingListFromJSON(jsonValue));
    }

    /**
     * Liste seulement les stages où je suis participant
     */
    async meetingsList(requestParameters: MeetingsListRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PaginatedMeetingList> {
        const response = await this.meetingsListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Information modifiable du participant courant
     */
    async meetingsMyParticipationPartialUpdateRaw(requestParameters: MeetingsMyParticipationPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<OwnMeetingUpdateParticipant>> {
        if (requestParameters['uuid'] == null) {
            throw new runtime.RequiredError(
                'uuid',
                'Required parameter "uuid" was null or undefined when calling meetingsMyParticipationPartialUpdate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // qrCodeTokenAuth authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // jwtAuth authentication
        }

        const response = await this.request({
            path: `/api/meetings/{uuid}/my-participation/`.replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters['uuid']))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: PatchedOwnMeetingUpdateParticipantToJSON(requestParameters['patchedOwnMeetingUpdateParticipant']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OwnMeetingUpdateParticipantFromJSON(jsonValue));
    }

    /**
     * Information modifiable du participant courant
     */
    async meetingsMyParticipationPartialUpdate(requestParameters: MeetingsMyParticipationPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<OwnMeetingUpdateParticipant> {
        const response = await this.meetingsMyParticipationPartialUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Information modifiable du participant courant
     */
    async meetingsMyParticipationRetrieveRaw(requestParameters: MeetingsMyParticipationRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<OwnMeetingParticipant>> {
        if (requestParameters['uuid'] == null) {
            throw new runtime.RequiredError(
                'uuid',
                'Required parameter "uuid" was null or undefined when calling meetingsMyParticipationRetrieve().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // qrCodeTokenAuth authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // jwtAuth authentication
        }

        const response = await this.request({
            path: `/api/meetings/{uuid}/my-participation/`.replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters['uuid']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OwnMeetingParticipantFromJSON(jsonValue));
    }

    /**
     * Information modifiable du participant courant
     */
    async meetingsMyParticipationRetrieve(requestParameters: MeetingsMyParticipationRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<OwnMeetingParticipant> {
        const response = await this.meetingsMyParticipationRetrieveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Information modifiable du participant courant
     */
    async meetingsMyParticipationUpdateRaw(requestParameters: MeetingsMyParticipationUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<OwnMeetingUpdateParticipant>> {
        if (requestParameters['uuid'] == null) {
            throw new runtime.RequiredError(
                'uuid',
                'Required parameter "uuid" was null or undefined when calling meetingsMyParticipationUpdate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // qrCodeTokenAuth authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // jwtAuth authentication
        }

        const response = await this.request({
            path: `/api/meetings/{uuid}/my-participation/`.replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters['uuid']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: OwnMeetingUpdateParticipantToJSON(requestParameters['ownMeetingUpdateParticipant']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OwnMeetingUpdateParticipantFromJSON(jsonValue));
    }

    /**
     * Information modifiable du participant courant
     */
    async meetingsMyParticipationUpdate(requestParameters: MeetingsMyParticipationUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<OwnMeetingUpdateParticipant> {
        const response = await this.meetingsMyParticipationUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Un \"Meeting\" correspond à une action de formation, un événement d\'un ou plusieurs jours
     */
    async meetingsPublicAccessRightsRetrieveRaw(requestParameters: MeetingsPublicAccessRightsRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UserAccessRights>> {
        if (requestParameters['uuid'] == null) {
            throw new runtime.RequiredError(
                'uuid',
                'Required parameter "uuid" was null or undefined when calling meetingsPublicAccessRightsRetrieve().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // qrCodeTokenAuth authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // jwtAuth authentication
        }

        const response = await this.request({
            path: `/api/meetings-public/{uuid}/access-rights/`.replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters['uuid']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserAccessRightsFromJSON(jsonValue));
    }

    /**
     * Un \"Meeting\" correspond à une action de formation, un événement d\'un ou plusieurs jours
     */
    async meetingsPublicAccessRightsRetrieve(requestParameters: MeetingsPublicAccessRightsRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UserAccessRights> {
        const response = await this.meetingsPublicAccessRightsRetrieveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Liste seulement les stages où je suis participant
     */
    async meetingsPublicListRaw(requestParameters: MeetingsPublicListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PaginatedMeetingPublicList>> {
        const queryParameters: any = {};

        if (requestParameters['centreRessource'] != null) {
            queryParameters['centre_ressource'] = requestParameters['centreRessource'];
        }

        if (requestParameters['endDate'] != null) {
            queryParameters['end_date'] = (requestParameters['endDate'] as any).toISOString().substring(0,10);
        }

        if (requestParameters['includePastYears'] != null) {
            queryParameters['include_past_years'] = requestParameters['includePastYears'];
        }

        if (requestParameters['limit'] != null) {
            queryParameters['limit'] = requestParameters['limit'];
        }

        if (requestParameters['meetingTypeIdIn'] != null) {
            queryParameters['meeting_type__id__in'] = requestParameters['meetingTypeIdIn']!.join(runtime.COLLECTION_FORMATS["csv"]);
        }

        if (requestParameters['offset'] != null) {
            queryParameters['offset'] = requestParameters['offset'];
        }

        if (requestParameters['opinionStatus'] != null) {
            queryParameters['opinion_status'] = requestParameters['opinionStatus'];
        }

        if (requestParameters['ordering'] != null) {
            queryParameters['ordering'] = requestParameters['ordering'];
        }

        if (requestParameters['search'] != null) {
            queryParameters['search'] = requestParameters['search'];
        }

        if (requestParameters['startDate'] != null) {
            queryParameters['start_date'] = (requestParameters['startDate'] as any).toISOString().substring(0,10);
        }

        if (requestParameters['withHasOpinions'] != null) {
            queryParameters['with_has_opinions'] = requestParameters['withHasOpinions'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // qrCodeTokenAuth authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // jwtAuth authentication
        }

        const response = await this.request({
            path: `/api/meetings-public/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PaginatedMeetingPublicListFromJSON(jsonValue));
    }

    /**
     * Liste seulement les stages où je suis participant
     */
    async meetingsPublicList(requestParameters: MeetingsPublicListRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PaginatedMeetingPublicList> {
        const response = await this.meetingsPublicListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Lit le stage
     */
    async meetingsPublicRetrieveRaw(requestParameters: MeetingsPublicRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MeetingPublic>> {
        if (requestParameters['uuid'] == null) {
            throw new runtime.RequiredError(
                'uuid',
                'Required parameter "uuid" was null or undefined when calling meetingsPublicRetrieve().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // qrCodeTokenAuth authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // jwtAuth authentication
        }

        const response = await this.request({
            path: `/api/meetings-public/{uuid}/`.replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters['uuid']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MeetingPublicFromJSON(jsonValue));
    }

    /**
     * Lit le stage
     */
    async meetingsPublicRetrieve(requestParameters: MeetingsPublicRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MeetingPublic> {
        const response = await this.meetingsPublicRetrieveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Place le stage fermé en statut prêt à être à anonymisé (ou annule la demande d\'anonymisation)
     */
    async meetingsRequestAnonymizationPartialUpdateRaw(requestParameters: MeetingsRequestAnonymizationPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AnonymizeMeeting>> {
        if (requestParameters['uuid'] == null) {
            throw new runtime.RequiredError(
                'uuid',
                'Required parameter "uuid" was null or undefined when calling meetingsRequestAnonymizationPartialUpdate().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['cancelAnonymization'] != null) {
            queryParameters['cancel-anonymization'] = requestParameters['cancelAnonymization'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // qrCodeTokenAuth authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // jwtAuth authentication
        }

        const response = await this.request({
            path: `/api/meetings/{uuid}/request-anonymization/`.replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters['uuid']))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AnonymizeMeetingFromJSON(jsonValue));
    }

    /**
     * Place le stage fermé en statut prêt à être à anonymisé (ou annule la demande d\'anonymisation)
     */
    async meetingsRequestAnonymizationPartialUpdate(requestParameters: MeetingsRequestAnonymizationPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AnonymizeMeeting> {
        const response = await this.meetingsRequestAnonymizationPartialUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Un \"Meeting\" correspond à une action de formation, un événement d\'un ou plusieurs jours
     */
    async meetingsResetParticipantsAvatarDestroyRaw(requestParameters: MeetingsResetParticipantsAvatarDestroyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['uuid'] == null) {
            throw new runtime.RequiredError(
                'uuid',
                'Required parameter "uuid" was null or undefined when calling meetingsResetParticipantsAvatarDestroy().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // qrCodeTokenAuth authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // jwtAuth authentication
        }

        const response = await this.request({
            path: `/api/meetings/{uuid}/reset-participants-avatar/`.replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters['uuid']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Un \"Meeting\" correspond à une action de formation, un événement d\'un ou plusieurs jours
     */
    async meetingsResetParticipantsAvatarDestroy(requestParameters: MeetingsResetParticipantsAvatarDestroyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.meetingsResetParticipantsAvatarDestroyRaw(requestParameters, initOverrides);
    }

    /**
     * Un \"Meeting\" correspond à une action de formation, un événement d\'un ou plusieurs jours
     */
    async meetingsResetParticipantsSignatureDestroyRaw(requestParameters: MeetingsResetParticipantsSignatureDestroyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['uuid'] == null) {
            throw new runtime.RequiredError(
                'uuid',
                'Required parameter "uuid" was null or undefined when calling meetingsResetParticipantsSignatureDestroy().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // qrCodeTokenAuth authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // jwtAuth authentication
        }

        const response = await this.request({
            path: `/api/meetings/{uuid}/reset-participants-signature/`.replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters['uuid']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Un \"Meeting\" correspond à une action de formation, un événement d\'un ou plusieurs jours
     */
    async meetingsResetParticipantsSignatureDestroy(requestParameters: MeetingsResetParticipantsSignatureDestroyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.meetingsResetParticipantsSignatureDestroyRaw(requestParameters, initOverrides);
    }

    /**
     * Lit le stage
     */
    async meetingsRetrieveRaw(requestParameters: MeetingsRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Meeting>> {
        if (requestParameters['uuid'] == null) {
            throw new runtime.RequiredError(
                'uuid',
                'Required parameter "uuid" was null or undefined when calling meetingsRetrieve().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // qrCodeTokenAuth authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // jwtAuth authentication
        }

        const response = await this.request({
            path: `/api/meetings/{uuid}/`.replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters['uuid']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MeetingFromJSON(jsonValue));
    }

    /**
     * Lit le stage
     */
    async meetingsRetrieve(requestParameters: MeetingsRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Meeting> {
        const response = await this.meetingsRetrieveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Récupère des informations sur un candidat depuis Tagada à partir de son numéro BAFA/BAFD
     */
    async meetingsRetrieveTagadaCandidateRetrieveRaw(requestParameters: MeetingsRetrieveTagadaCandidateRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<TagadaCandidate>> {
        if (requestParameters['uuid'] == null) {
            throw new runtime.RequiredError(
                'uuid',
                'Required parameter "uuid" was null or undefined when calling meetingsRetrieveTagadaCandidateRetrieve().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['candidate'] != null) {
            queryParameters['candidate'] = requestParameters['candidate'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // qrCodeTokenAuth authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // jwtAuth authentication
        }

        const response = await this.request({
            path: `/api/meetings/{uuid}/retrieve-tagada-candidate/`.replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters['uuid']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TagadaCandidateFromJSON(jsonValue));
    }

    /**
     * Récupère des informations sur un candidat depuis Tagada à partir de son numéro BAFA/BAFD
     */
    async meetingsRetrieveTagadaCandidateRetrieve(requestParameters: MeetingsRetrieveTagadaCandidateRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<TagadaCandidate> {
        const response = await this.meetingsRetrieveTagadaCandidateRetrieveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Synchronise les avis de stages des participants de la formation avec Tagada : l\'intranet ddcs
     */
    async meetingsSyncAvisTagadaCreateRaw(requestParameters: MeetingsSyncAvisTagadaCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Meeting>> {
        if (requestParameters['uuid'] == null) {
            throw new runtime.RequiredError(
                'uuid',
                'Required parameter "uuid" was null or undefined when calling meetingsSyncAvisTagadaCreate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // qrCodeTokenAuth authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // jwtAuth authentication
        }

        const response = await this.request({
            path: `/api/meetings/{uuid}/sync-avis-tagada/`.replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters['uuid']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MeetingFromJSON(jsonValue));
    }

    /**
     * Synchronise les avis de stages des participants de la formation avec Tagada : l\'intranet ddcs
     */
    async meetingsSyncAvisTagadaCreate(requestParameters: MeetingsSyncAvisTagadaCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Meeting> {
        const response = await this.meetingsSyncAvisTagadaCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Synchronise une formation. Synchronise aussi les participants à la formation
     */
    async meetingsSyncFromIntranetCreateRaw(requestParameters: MeetingsSyncFromIntranetCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SyncFromIntranet>> {
        if (requestParameters['uuid'] == null) {
            throw new runtime.RequiredError(
                'uuid',
                'Required parameter "uuid" was null or undefined when calling meetingsSyncFromIntranetCreate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // qrCodeTokenAuth authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // jwtAuth authentication
        }

        const response = await this.request({
            path: `/api/meetings/{uuid}/sync-from-intranet/`.replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters['uuid']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SyncFromIntranetFromJSON(jsonValue));
    }

    /**
     * Synchronise une formation. Synchronise aussi les participants à la formation
     */
    async meetingsSyncFromIntranetCreate(requestParameters: MeetingsSyncFromIntranetCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SyncFromIntranet> {
        const response = await this.meetingsSyncFromIntranetCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Synchronise  les participants de la formation avec Tagada : l\'intranet ddcs
     */
    async meetingsSyncParticipantsTagadaCreateRaw(requestParameters: MeetingsSyncParticipantsTagadaCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Meeting>> {
        if (requestParameters['uuid'] == null) {
            throw new runtime.RequiredError(
                'uuid',
                'Required parameter "uuid" was null or undefined when calling meetingsSyncParticipantsTagadaCreate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // qrCodeTokenAuth authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // jwtAuth authentication
        }

        const response = await this.request({
            path: `/api/meetings/{uuid}/sync-participants-tagada/`.replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters['uuid']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MeetingFromJSON(jsonValue));
    }

    /**
     * Synchronise  les participants de la formation avec Tagada : l\'intranet ddcs
     */
    async meetingsSyncParticipantsTagadaCreate(requestParameters: MeetingsSyncParticipantsTagadaCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Meeting> {
        const response = await this.meetingsSyncParticipantsTagadaCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
