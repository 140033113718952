/* tslint:disable */
/* eslint-disable */
/**
 * Digiform API
 * Digiform API pour les SGDF
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  BrancheEnum,
  MeetingParticipant,
  NotNestedDailyTaskTeam,
  NotNestedMeetingRole,
  NotNestedTeam,
  NotNestedUser,
  OpinionParticipant,
  PaginatedNoCommentMeetingParticipantList,
  PaginatedOpinionParticipantList,
  PatchedMeetingParticipant,
  PatchedOpinionParticipant,
  ReadonlyStructure,
  SessionStatusEnum,
  TagadaAvisStatusEnum,
  TagadaCandidateStatusEnum,
  Track,
} from '../models/index';
import {
    BrancheEnumFromJSON,
    BrancheEnumToJSON,
    MeetingParticipantFromJSON,
    MeetingParticipantToJSON,
    NotNestedDailyTaskTeamFromJSON,
    NotNestedDailyTaskTeamToJSON,
    NotNestedMeetingRoleFromJSON,
    NotNestedMeetingRoleToJSON,
    NotNestedTeamFromJSON,
    NotNestedTeamToJSON,
    NotNestedUserFromJSON,
    NotNestedUserToJSON,
    OpinionParticipantFromJSON,
    OpinionParticipantToJSON,
    PaginatedNoCommentMeetingParticipantListFromJSON,
    PaginatedNoCommentMeetingParticipantListToJSON,
    PaginatedOpinionParticipantListFromJSON,
    PaginatedOpinionParticipantListToJSON,
    PatchedMeetingParticipantFromJSON,
    PatchedMeetingParticipantToJSON,
    PatchedOpinionParticipantFromJSON,
    PatchedOpinionParticipantToJSON,
    ReadonlyStructureFromJSON,
    ReadonlyStructureToJSON,
    SessionStatusEnumFromJSON,
    SessionStatusEnumToJSON,
    TagadaAvisStatusEnumFromJSON,
    TagadaAvisStatusEnumToJSON,
    TagadaCandidateStatusEnumFromJSON,
    TagadaCandidateStatusEnumToJSON,
    TrackFromJSON,
    TrackToJSON,
} from '../models/index';

export interface MeetingsParticipantsAvatarMediaRetrieveRequest {
    meetingUuid: string;
    uuid: string;
}

export interface MeetingsParticipantsAvatarMediaUpdateRequest {
    meetingUuid: string;
    uuid: string;
    id: string;
    userId: string;
    firstName: string;
    lastName: string;
    usageFirstName: string;
    usageLastName: string;
    created: Date;
    updated: Date;
    meeting: string;
    user: NotNestedUser;
    role: NotNestedMeetingRole;
    dailyTaskTeam: NotNestedDailyTaskTeam;
    teams: Array<NotNestedTeam>;
    fonction: string;
    structurePrincipale: ReadonlyStructure | null;
    track: Track;
    canceled: boolean;
    observationCount: number;
    trackId: string | null;
    lastSync: Date | null;
    tagadaSyncObject: string;
    avisPrevalidation: string | null;
    avisValidation: string | null;
    avisValidationGestionnaire: string | null;
    signatureUrl: string;
    signatureAvailable: boolean;
    avatarUrl: string;
    avatarAvailable: boolean;
    userAvatarUrl: string;
    userAvatarAvailable: boolean;
    userLastSync: Date | null;
    rgUser: NotNestedUser;
    dtUser: NotNestedUser;
    gender: string;
    birthName: string;
    whichAdministrativeLastname: string;
    whichAdministrativeFirstname: string;
    phone: string;
    email: string;
    branche?: BrancheEnum;
    numBafaBafd?: string;
    foodRegime?: string;
    sessionStatus?: SessionStatusEnum;
    complementInformation?: string;
    hidden?: boolean;
    birthdate?: Date | null;
    hebergement?: string;
    isBafaBafdCandidate?: boolean;
    tagadaCandidateStatus?: TagadaCandidateStatusEnum;
    tagadaAvisStatus?: TagadaAvisStatusEnum;
    tagadaAvisLastSync?: Date | null;
    ageAtMeetingStart?: number;
    startInterview?: string | null;
    middleInterview?: string | null;
    endInterview?: string | null;
    sgdfOpinion?: string | null;
    jsOpinion?: string | null;
}

export interface MeetingsParticipantsListRequest {
    meetingUuid: string;
    uuid: string;
    ordering?: string;
    page?: number;
    pageSize?: number;
    search?: string;
}

export interface MeetingsParticipantsOpinionListListRequest {
    meetingUuid: string;
    uuid: string;
    ordering?: string;
    page?: number;
    pageSize?: number;
    search?: string;
}

export interface MeetingsParticipantsOpinionPartialUpdateRequest {
    meetingUuid: string;
    uuid: string;
    patchedOpinionParticipant?: Omit<PatchedOpinionParticipant, 'id'|'meeting_id'|'role'|'admin_first_name'|'admin_last_name'>;
}

export interface MeetingsParticipantsOpinionRetrieveRequest {
    meetingUuid: string;
    uuid: string;
}

export interface MeetingsParticipantsOpinionUpdateRequest {
    meetingUuid: string;
    uuid: string;
    opinionParticipant?: Omit<OpinionParticipant, 'id'|'meeting_id'|'role'|'admin_first_name'|'admin_last_name'>;
}

export interface MeetingsParticipantsPartialUpdateRequest {
    meetingUuid: string;
    uuid: string;
    patchedMeetingParticipant?: Omit<PatchedMeetingParticipant, 'id'|'user_id'|'created'|'updated'|'meeting'|'user'|'role'|'daily_task_team'|'teams'|'fonction'|'structure_principale'|'track'|'canceled'|'observation_count'|'last_sync'|'tagada_sync_object'|'avis_prevalidation'|'avis_validation'|'avis_validation_gestionnaire'|'signature_url'|'signature_available'|'avatar_url'|'avatar_available'|'user_avatar_url'|'user_avatar_available'>;
}

export interface MeetingsParticipantsResetAvatarDestroyRequest {
    meetingUuid: string;
    uuid: string;
}

export interface MeetingsParticipantsResetSignatureDestroyRequest {
    meetingUuid: string;
    uuid: string;
}

export interface MeetingsParticipantsRetrieveRequest {
    meetingUuid: string;
    uuid: string;
}

export interface MeetingsParticipantsSignatureMediaRetrieveRequest {
    meetingUuid: string;
    uuid: string;
}

export interface MeetingsParticipantsSignatureMediaUpdateRequest {
    meetingUuid: string;
    uuid: string;
    id: string;
    userId: string;
    firstName: string;
    lastName: string;
    usageFirstName: string;
    usageLastName: string;
    created: Date;
    updated: Date;
    meeting: string;
    user: NotNestedUser;
    role: NotNestedMeetingRole;
    dailyTaskTeam: NotNestedDailyTaskTeam;
    teams: Array<NotNestedTeam>;
    fonction: string;
    structurePrincipale: ReadonlyStructure | null;
    track: Track;
    canceled: boolean;
    observationCount: number;
    trackId: string | null;
    lastSync: Date | null;
    tagadaSyncObject: string;
    avisPrevalidation: string | null;
    avisValidation: string | null;
    avisValidationGestionnaire: string | null;
    signatureUrl: string;
    signatureAvailable: boolean;
    avatarUrl: string;
    avatarAvailable: boolean;
    userAvatarUrl: string;
    userAvatarAvailable: boolean;
    userLastSync: Date | null;
    rgUser: NotNestedUser;
    dtUser: NotNestedUser;
    gender: string;
    birthName: string;
    whichAdministrativeLastname: string;
    whichAdministrativeFirstname: string;
    phone: string;
    email: string;
    branche?: BrancheEnum;
    numBafaBafd?: string;
    foodRegime?: string;
    sessionStatus?: SessionStatusEnum;
    complementInformation?: string;
    hidden?: boolean;
    birthdate?: Date | null;
    hebergement?: string;
    isBafaBafdCandidate?: boolean;
    tagadaCandidateStatus?: TagadaCandidateStatusEnum;
    tagadaAvisStatus?: TagadaAvisStatusEnum;
    tagadaAvisLastSync?: Date | null;
    ageAtMeetingStart?: number;
    startInterview?: string | null;
    middleInterview?: string | null;
    endInterview?: string | null;
    sgdfOpinion?: string | null;
    jsOpinion?: string | null;
}

export interface MeetingsParticipantsUpdateRequest {
    meetingUuid: string;
    uuid: string;
    meetingParticipant: Omit<MeetingParticipant, 'id'|'user_id'|'created'|'updated'|'meeting'|'user'|'role'|'daily_task_team'|'teams'|'fonction'|'structure_principale'|'track'|'canceled'|'observation_count'|'last_sync'|'tagada_sync_object'|'avis_prevalidation'|'avis_validation'|'avis_validation_gestionnaire'|'signature_url'|'signature_available'|'avatar_url'|'avatar_available'|'user_avatar_url'|'user_avatar_available'>;
}

/**
 * 
 */
export class ParticipantsApi extends runtime.BaseAPI {

    /**
     * Un \"MeetingParticipant\" correspond à une inscription à une action de formation ou d\'événement (Meeting)
     */
    async meetingsParticipantsAvatarMediaRetrieveRaw(requestParameters: MeetingsParticipantsAvatarMediaRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MeetingParticipant>> {
        if (requestParameters['meetingUuid'] == null) {
            throw new runtime.RequiredError(
                'meetingUuid',
                'Required parameter "meetingUuid" was null or undefined when calling meetingsParticipantsAvatarMediaRetrieve().'
            );
        }

        if (requestParameters['uuid'] == null) {
            throw new runtime.RequiredError(
                'uuid',
                'Required parameter "uuid" was null or undefined when calling meetingsParticipantsAvatarMediaRetrieve().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // qrCodeTokenAuth authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // jwtAuth authentication
        }

        const response = await this.request({
            path: `/api/meetings/{meeting_uuid}/participants/{uuid}/avatar-media/`.replace(`{${"meeting_uuid"}}`, encodeURIComponent(String(requestParameters['meetingUuid']))).replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters['uuid']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MeetingParticipantFromJSON(jsonValue));
    }

    /**
     * Un \"MeetingParticipant\" correspond à une inscription à une action de formation ou d\'événement (Meeting)
     */
    async meetingsParticipantsAvatarMediaRetrieve(requestParameters: MeetingsParticipantsAvatarMediaRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MeetingParticipant> {
        const response = await this.meetingsParticipantsAvatarMediaRetrieveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Un \"MeetingParticipant\" correspond à une inscription à une action de formation ou d\'événement (Meeting)
     */
    async meetingsParticipantsAvatarMediaUpdateRaw(requestParameters: MeetingsParticipantsAvatarMediaUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MeetingParticipant>> {
        if (requestParameters['meetingUuid'] == null) {
            throw new runtime.RequiredError(
                'meetingUuid',
                'Required parameter "meetingUuid" was null or undefined when calling meetingsParticipantsAvatarMediaUpdate().'
            );
        }

        if (requestParameters['uuid'] == null) {
            throw new runtime.RequiredError(
                'uuid',
                'Required parameter "uuid" was null or undefined when calling meetingsParticipantsAvatarMediaUpdate().'
            );
        }

        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling meetingsParticipantsAvatarMediaUpdate().'
            );
        }

        if (requestParameters['userId'] == null) {
            throw new runtime.RequiredError(
                'userId',
                'Required parameter "userId" was null or undefined when calling meetingsParticipantsAvatarMediaUpdate().'
            );
        }

        if (requestParameters['firstName'] == null) {
            throw new runtime.RequiredError(
                'firstName',
                'Required parameter "firstName" was null or undefined when calling meetingsParticipantsAvatarMediaUpdate().'
            );
        }

        if (requestParameters['lastName'] == null) {
            throw new runtime.RequiredError(
                'lastName',
                'Required parameter "lastName" was null or undefined when calling meetingsParticipantsAvatarMediaUpdate().'
            );
        }

        if (requestParameters['usageFirstName'] == null) {
            throw new runtime.RequiredError(
                'usageFirstName',
                'Required parameter "usageFirstName" was null or undefined when calling meetingsParticipantsAvatarMediaUpdate().'
            );
        }

        if (requestParameters['usageLastName'] == null) {
            throw new runtime.RequiredError(
                'usageLastName',
                'Required parameter "usageLastName" was null or undefined when calling meetingsParticipantsAvatarMediaUpdate().'
            );
        }

        if (requestParameters['created'] == null) {
            throw new runtime.RequiredError(
                'created',
                'Required parameter "created" was null or undefined when calling meetingsParticipantsAvatarMediaUpdate().'
            );
        }

        if (requestParameters['updated'] == null) {
            throw new runtime.RequiredError(
                'updated',
                'Required parameter "updated" was null or undefined when calling meetingsParticipantsAvatarMediaUpdate().'
            );
        }

        if (requestParameters['meeting'] == null) {
            throw new runtime.RequiredError(
                'meeting',
                'Required parameter "meeting" was null or undefined when calling meetingsParticipantsAvatarMediaUpdate().'
            );
        }

        if (requestParameters['user'] == null) {
            throw new runtime.RequiredError(
                'user',
                'Required parameter "user" was null or undefined when calling meetingsParticipantsAvatarMediaUpdate().'
            );
        }

        if (requestParameters['role'] == null) {
            throw new runtime.RequiredError(
                'role',
                'Required parameter "role" was null or undefined when calling meetingsParticipantsAvatarMediaUpdate().'
            );
        }

        if (requestParameters['dailyTaskTeam'] == null) {
            throw new runtime.RequiredError(
                'dailyTaskTeam',
                'Required parameter "dailyTaskTeam" was null or undefined when calling meetingsParticipantsAvatarMediaUpdate().'
            );
        }

        if (requestParameters['teams'] == null) {
            throw new runtime.RequiredError(
                'teams',
                'Required parameter "teams" was null or undefined when calling meetingsParticipantsAvatarMediaUpdate().'
            );
        }

        if (requestParameters['fonction'] == null) {
            throw new runtime.RequiredError(
                'fonction',
                'Required parameter "fonction" was null or undefined when calling meetingsParticipantsAvatarMediaUpdate().'
            );
        }

        if (requestParameters['structurePrincipale'] == null) {
            throw new runtime.RequiredError(
                'structurePrincipale',
                'Required parameter "structurePrincipale" was null or undefined when calling meetingsParticipantsAvatarMediaUpdate().'
            );
        }

        if (requestParameters['track'] == null) {
            throw new runtime.RequiredError(
                'track',
                'Required parameter "track" was null or undefined when calling meetingsParticipantsAvatarMediaUpdate().'
            );
        }

        if (requestParameters['canceled'] == null) {
            throw new runtime.RequiredError(
                'canceled',
                'Required parameter "canceled" was null or undefined when calling meetingsParticipantsAvatarMediaUpdate().'
            );
        }

        if (requestParameters['observationCount'] == null) {
            throw new runtime.RequiredError(
                'observationCount',
                'Required parameter "observationCount" was null or undefined when calling meetingsParticipantsAvatarMediaUpdate().'
            );
        }

        if (requestParameters['trackId'] == null) {
            throw new runtime.RequiredError(
                'trackId',
                'Required parameter "trackId" was null or undefined when calling meetingsParticipantsAvatarMediaUpdate().'
            );
        }

        if (requestParameters['lastSync'] == null) {
            throw new runtime.RequiredError(
                'lastSync',
                'Required parameter "lastSync" was null or undefined when calling meetingsParticipantsAvatarMediaUpdate().'
            );
        }

        if (requestParameters['tagadaSyncObject'] == null) {
            throw new runtime.RequiredError(
                'tagadaSyncObject',
                'Required parameter "tagadaSyncObject" was null or undefined when calling meetingsParticipantsAvatarMediaUpdate().'
            );
        }

        if (requestParameters['avisPrevalidation'] == null) {
            throw new runtime.RequiredError(
                'avisPrevalidation',
                'Required parameter "avisPrevalidation" was null or undefined when calling meetingsParticipantsAvatarMediaUpdate().'
            );
        }

        if (requestParameters['avisValidation'] == null) {
            throw new runtime.RequiredError(
                'avisValidation',
                'Required parameter "avisValidation" was null or undefined when calling meetingsParticipantsAvatarMediaUpdate().'
            );
        }

        if (requestParameters['avisValidationGestionnaire'] == null) {
            throw new runtime.RequiredError(
                'avisValidationGestionnaire',
                'Required parameter "avisValidationGestionnaire" was null or undefined when calling meetingsParticipantsAvatarMediaUpdate().'
            );
        }

        if (requestParameters['signatureUrl'] == null) {
            throw new runtime.RequiredError(
                'signatureUrl',
                'Required parameter "signatureUrl" was null or undefined when calling meetingsParticipantsAvatarMediaUpdate().'
            );
        }

        if (requestParameters['signatureAvailable'] == null) {
            throw new runtime.RequiredError(
                'signatureAvailable',
                'Required parameter "signatureAvailable" was null or undefined when calling meetingsParticipantsAvatarMediaUpdate().'
            );
        }

        if (requestParameters['avatarUrl'] == null) {
            throw new runtime.RequiredError(
                'avatarUrl',
                'Required parameter "avatarUrl" was null or undefined when calling meetingsParticipantsAvatarMediaUpdate().'
            );
        }

        if (requestParameters['avatarAvailable'] == null) {
            throw new runtime.RequiredError(
                'avatarAvailable',
                'Required parameter "avatarAvailable" was null or undefined when calling meetingsParticipantsAvatarMediaUpdate().'
            );
        }

        if (requestParameters['userAvatarUrl'] == null) {
            throw new runtime.RequiredError(
                'userAvatarUrl',
                'Required parameter "userAvatarUrl" was null or undefined when calling meetingsParticipantsAvatarMediaUpdate().'
            );
        }

        if (requestParameters['userAvatarAvailable'] == null) {
            throw new runtime.RequiredError(
                'userAvatarAvailable',
                'Required parameter "userAvatarAvailable" was null or undefined when calling meetingsParticipantsAvatarMediaUpdate().'
            );
        }

        if (requestParameters['userLastSync'] == null) {
            throw new runtime.RequiredError(
                'userLastSync',
                'Required parameter "userLastSync" was null or undefined when calling meetingsParticipantsAvatarMediaUpdate().'
            );
        }

        if (requestParameters['rgUser'] == null) {
            throw new runtime.RequiredError(
                'rgUser',
                'Required parameter "rgUser" was null or undefined when calling meetingsParticipantsAvatarMediaUpdate().'
            );
        }

        if (requestParameters['dtUser'] == null) {
            throw new runtime.RequiredError(
                'dtUser',
                'Required parameter "dtUser" was null or undefined when calling meetingsParticipantsAvatarMediaUpdate().'
            );
        }

        if (requestParameters['gender'] == null) {
            throw new runtime.RequiredError(
                'gender',
                'Required parameter "gender" was null or undefined when calling meetingsParticipantsAvatarMediaUpdate().'
            );
        }

        if (requestParameters['birthName'] == null) {
            throw new runtime.RequiredError(
                'birthName',
                'Required parameter "birthName" was null or undefined when calling meetingsParticipantsAvatarMediaUpdate().'
            );
        }

        if (requestParameters['whichAdministrativeLastname'] == null) {
            throw new runtime.RequiredError(
                'whichAdministrativeLastname',
                'Required parameter "whichAdministrativeLastname" was null or undefined when calling meetingsParticipantsAvatarMediaUpdate().'
            );
        }

        if (requestParameters['whichAdministrativeFirstname'] == null) {
            throw new runtime.RequiredError(
                'whichAdministrativeFirstname',
                'Required parameter "whichAdministrativeFirstname" was null or undefined when calling meetingsParticipantsAvatarMediaUpdate().'
            );
        }

        if (requestParameters['phone'] == null) {
            throw new runtime.RequiredError(
                'phone',
                'Required parameter "phone" was null or undefined when calling meetingsParticipantsAvatarMediaUpdate().'
            );
        }

        if (requestParameters['email'] == null) {
            throw new runtime.RequiredError(
                'email',
                'Required parameter "email" was null or undefined when calling meetingsParticipantsAvatarMediaUpdate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // qrCodeTokenAuth authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // jwtAuth authentication
        }

        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters['id'] != null) {
            formParams.append('id', requestParameters['id'] as any);
        }

        if (requestParameters['userId'] != null) {
            formParams.append('user_id', requestParameters['userId'] as any);
        }

        if (requestParameters['firstName'] != null) {
            formParams.append('first_name', requestParameters['firstName'] as any);
        }

        if (requestParameters['lastName'] != null) {
            formParams.append('last_name', requestParameters['lastName'] as any);
        }

        if (requestParameters['usageFirstName'] != null) {
            formParams.append('usage_first_name', requestParameters['usageFirstName'] as any);
        }

        if (requestParameters['usageLastName'] != null) {
            formParams.append('usage_last_name', requestParameters['usageLastName'] as any);
        }

        if (requestParameters['created'] != null) {
            formParams.append('created', (requestParameters['created'] as any).toISOString());
        }

        if (requestParameters['updated'] != null) {
            formParams.append('updated', (requestParameters['updated'] as any).toISOString());
        }

        if (requestParameters['meeting'] != null) {
            formParams.append('meeting', requestParameters['meeting'] as any);
        }

        if (requestParameters['user'] != null) {
            formParams.append('user', new Blob([JSON.stringify(NotNestedUserToJSON(requestParameters['user']))], { type: "application/json", }));
                    }

        if (requestParameters['role'] != null) {
            formParams.append('role', new Blob([JSON.stringify(NotNestedMeetingRoleToJSON(requestParameters['role']))], { type: "application/json", }));
                    }

        if (requestParameters['dailyTaskTeam'] != null) {
            formParams.append('daily_task_team', new Blob([JSON.stringify(NotNestedDailyTaskTeamToJSON(requestParameters['dailyTaskTeam']))], { type: "application/json", }));
                    }

        if (requestParameters['teams'] != null) {
            formParams.append('teams', requestParameters['teams']!.join(runtime.COLLECTION_FORMATS["csv"]));
        }

        if (requestParameters['fonction'] != null) {
            formParams.append('fonction', requestParameters['fonction'] as any);
        }

        if (requestParameters['structurePrincipale'] != null) {
            formParams.append('structure_principale', new Blob([JSON.stringify(ReadonlyStructureToJSON(requestParameters['structurePrincipale']))], { type: "application/json", }));
                    }

        if (requestParameters['branche'] != null) {
            formParams.append('branche', requestParameters['branche'] as any);
        }

        if (requestParameters['track'] != null) {
            formParams.append('track', new Blob([JSON.stringify(TrackToJSON(requestParameters['track']))], { type: "application/json", }));
                    }

        if (requestParameters['numBafaBafd'] != null) {
            formParams.append('num_bafa_bafd', requestParameters['numBafaBafd'] as any);
        }

        if (requestParameters['foodRegime'] != null) {
            formParams.append('food_regime', requestParameters['foodRegime'] as any);
        }

        if (requestParameters['sessionStatus'] != null) {
            formParams.append('session_status', requestParameters['sessionStatus'] as any);
        }

        if (requestParameters['complementInformation'] != null) {
            formParams.append('complement_information', requestParameters['complementInformation'] as any);
        }

        if (requestParameters['canceled'] != null) {
            formParams.append('canceled', requestParameters['canceled'] as any);
        }

        if (requestParameters['hidden'] != null) {
            formParams.append('hidden', requestParameters['hidden'] as any);
        }

        if (requestParameters['observationCount'] != null) {
            formParams.append('observation_count', requestParameters['observationCount'] as any);
        }

        if (requestParameters['birthdate'] != null) {
            formParams.append('birthdate', requestParameters['birthdate'] as any);
        }

        if (requestParameters['hebergement'] != null) {
            formParams.append('hebergement', requestParameters['hebergement'] as any);
        }

        if (requestParameters['trackId'] != null) {
            formParams.append('track_id', requestParameters['trackId'] as any);
        }

        if (requestParameters['lastSync'] != null) {
            formParams.append('last_sync', (requestParameters['lastSync'] as any).toISOString());
        }

        if (requestParameters['isBafaBafdCandidate'] != null) {
            formParams.append('is_bafa_bafd_candidate', requestParameters['isBafaBafdCandidate'] as any);
        }

        if (requestParameters['tagadaCandidateStatus'] != null) {
            formParams.append('tagada_candidate_status', requestParameters['tagadaCandidateStatus'] as any);
        }

        if (requestParameters['tagadaAvisStatus'] != null) {
            formParams.append('tagada_avis_status', requestParameters['tagadaAvisStatus'] as any);
        }

        if (requestParameters['tagadaAvisLastSync'] != null) {
            formParams.append('tagada_avis_last_sync', (requestParameters['tagadaAvisLastSync'] as any).toISOString());
        }

        if (requestParameters['tagadaSyncObject'] != null) {
            formParams.append('tagada_sync_object', requestParameters['tagadaSyncObject'] as any);
        }

        if (requestParameters['ageAtMeetingStart'] != null) {
            formParams.append('age_at_meeting_start', requestParameters['ageAtMeetingStart'] as any);
        }

        if (requestParameters['startInterview'] != null) {
            formParams.append('start_interview', requestParameters['startInterview'] as any);
        }

        if (requestParameters['middleInterview'] != null) {
            formParams.append('middle_interview', requestParameters['middleInterview'] as any);
        }

        if (requestParameters['endInterview'] != null) {
            formParams.append('end_interview', requestParameters['endInterview'] as any);
        }

        if (requestParameters['sgdfOpinion'] != null) {
            formParams.append('sgdf_opinion', requestParameters['sgdfOpinion'] as any);
        }

        if (requestParameters['jsOpinion'] != null) {
            formParams.append('js_opinion', requestParameters['jsOpinion'] as any);
        }

        if (requestParameters['avisPrevalidation'] != null) {
            formParams.append('avis_prevalidation', requestParameters['avisPrevalidation'] as any);
        }

        if (requestParameters['avisValidation'] != null) {
            formParams.append('avis_validation', requestParameters['avisValidation'] as any);
        }

        if (requestParameters['avisValidationGestionnaire'] != null) {
            formParams.append('avis_validation_gestionnaire', requestParameters['avisValidationGestionnaire'] as any);
        }

        if (requestParameters['signatureUrl'] != null) {
            formParams.append('signature_url', requestParameters['signatureUrl'] as any);
        }

        if (requestParameters['signatureAvailable'] != null) {
            formParams.append('signature_available', requestParameters['signatureAvailable'] as any);
        }

        if (requestParameters['avatarUrl'] != null) {
            formParams.append('avatar_url', requestParameters['avatarUrl'] as any);
        }

        if (requestParameters['avatarAvailable'] != null) {
            formParams.append('avatar_available', requestParameters['avatarAvailable'] as any);
        }

        if (requestParameters['userAvatarUrl'] != null) {
            formParams.append('user_avatar_url', requestParameters['userAvatarUrl'] as any);
        }

        if (requestParameters['userAvatarAvailable'] != null) {
            formParams.append('user_avatar_available', requestParameters['userAvatarAvailable'] as any);
        }

        if (requestParameters['userLastSync'] != null) {
            formParams.append('user_last_sync', (requestParameters['userLastSync'] as any).toISOString());
        }

        if (requestParameters['rgUser'] != null) {
            formParams.append('rg_user', new Blob([JSON.stringify(NotNestedUserToJSON(requestParameters['rgUser']))], { type: "application/json", }));
                    }

        if (requestParameters['dtUser'] != null) {
            formParams.append('dt_user', new Blob([JSON.stringify(NotNestedUserToJSON(requestParameters['dtUser']))], { type: "application/json", }));
                    }

        if (requestParameters['gender'] != null) {
            formParams.append('gender', requestParameters['gender'] as any);
        }

        if (requestParameters['birthName'] != null) {
            formParams.append('birth_name', requestParameters['birthName'] as any);
        }

        if (requestParameters['whichAdministrativeLastname'] != null) {
            formParams.append('which_administrative_lastname', requestParameters['whichAdministrativeLastname'] as any);
        }

        if (requestParameters['whichAdministrativeFirstname'] != null) {
            formParams.append('which_administrative_firstname', requestParameters['whichAdministrativeFirstname'] as any);
        }

        if (requestParameters['phone'] != null) {
            formParams.append('phone', requestParameters['phone'] as any);
        }

        if (requestParameters['email'] != null) {
            formParams.append('email', requestParameters['email'] as any);
        }

        const response = await this.request({
            path: `/api/meetings/{meeting_uuid}/participants/{uuid}/avatar-media/`.replace(`{${"meeting_uuid"}}`, encodeURIComponent(String(requestParameters['meetingUuid']))).replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters['uuid']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MeetingParticipantFromJSON(jsonValue));
    }

    /**
     * Un \"MeetingParticipant\" correspond à une inscription à une action de formation ou d\'événement (Meeting)
     */
    async meetingsParticipantsAvatarMediaUpdate(requestParameters: MeetingsParticipantsAvatarMediaUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MeetingParticipant> {
        const response = await this.meetingsParticipantsAvatarMediaUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Liste tous les participants d\'une action de formation où je suis directeur/formateur
     */
    async meetingsParticipantsListRaw(requestParameters: MeetingsParticipantsListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PaginatedNoCommentMeetingParticipantList>> {
        if (requestParameters['meetingUuid'] == null) {
            throw new runtime.RequiredError(
                'meetingUuid',
                'Required parameter "meetingUuid" was null or undefined when calling meetingsParticipantsList().'
            );
        }

        if (requestParameters['uuid'] == null) {
            throw new runtime.RequiredError(
                'uuid',
                'Required parameter "uuid" was null or undefined when calling meetingsParticipantsList().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['ordering'] != null) {
            queryParameters['ordering'] = requestParameters['ordering'];
        }

        if (requestParameters['page'] != null) {
            queryParameters['page'] = requestParameters['page'];
        }

        if (requestParameters['pageSize'] != null) {
            queryParameters['page_size'] = requestParameters['pageSize'];
        }

        if (requestParameters['search'] != null) {
            queryParameters['search'] = requestParameters['search'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // qrCodeTokenAuth authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // jwtAuth authentication
        }

        const response = await this.request({
            path: `/api/meetings/{meeting_uuid}/participants/`.replace(`{${"meeting_uuid"}}`, encodeURIComponent(String(requestParameters['meetingUuid']))).replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters['uuid']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PaginatedNoCommentMeetingParticipantListFromJSON(jsonValue));
    }

    /**
     * Liste tous les participants d\'une action de formation où je suis directeur/formateur
     */
    async meetingsParticipantsList(requestParameters: MeetingsParticipantsListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PaginatedNoCommentMeetingParticipantList> {
        const response = await this.meetingsParticipantsListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Récupère la liste des participants ainsi que leurs avis
     */
    async meetingsParticipantsOpinionListListRaw(requestParameters: MeetingsParticipantsOpinionListListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PaginatedOpinionParticipantList>> {
        if (requestParameters['meetingUuid'] == null) {
            throw new runtime.RequiredError(
                'meetingUuid',
                'Required parameter "meetingUuid" was null or undefined when calling meetingsParticipantsOpinionListList().'
            );
        }

        if (requestParameters['uuid'] == null) {
            throw new runtime.RequiredError(
                'uuid',
                'Required parameter "uuid" was null or undefined when calling meetingsParticipantsOpinionListList().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['ordering'] != null) {
            queryParameters['ordering'] = requestParameters['ordering'];
        }

        if (requestParameters['page'] != null) {
            queryParameters['page'] = requestParameters['page'];
        }

        if (requestParameters['pageSize'] != null) {
            queryParameters['page_size'] = requestParameters['pageSize'];
        }

        if (requestParameters['search'] != null) {
            queryParameters['search'] = requestParameters['search'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // qrCodeTokenAuth authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // jwtAuth authentication
        }

        const response = await this.request({
            path: `/api/meetings/{meeting_uuid}/participants/opinion-list/`.replace(`{${"meeting_uuid"}}`, encodeURIComponent(String(requestParameters['meetingUuid']))).replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters['uuid']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PaginatedOpinionParticipantListFromJSON(jsonValue));
    }

    /**
     * Récupère la liste des participants ainsi que leurs avis
     */
    async meetingsParticipantsOpinionListList(requestParameters: MeetingsParticipantsOpinionListListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PaginatedOpinionParticipantList> {
        const response = await this.meetingsParticipantsOpinionListListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Change les avis d\'un participant
     */
    async meetingsParticipantsOpinionPartialUpdateRaw(requestParameters: MeetingsParticipantsOpinionPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<OpinionParticipant>> {
        if (requestParameters['meetingUuid'] == null) {
            throw new runtime.RequiredError(
                'meetingUuid',
                'Required parameter "meetingUuid" was null or undefined when calling meetingsParticipantsOpinionPartialUpdate().'
            );
        }

        if (requestParameters['uuid'] == null) {
            throw new runtime.RequiredError(
                'uuid',
                'Required parameter "uuid" was null or undefined when calling meetingsParticipantsOpinionPartialUpdate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // qrCodeTokenAuth authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // jwtAuth authentication
        }

        const response = await this.request({
            path: `/api/meetings/{meeting_uuid}/participants/{uuid}/opinion/`.replace(`{${"meeting_uuid"}}`, encodeURIComponent(String(requestParameters['meetingUuid']))).replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters['uuid']))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: PatchedOpinionParticipantToJSON(requestParameters['patchedOpinionParticipant']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OpinionParticipantFromJSON(jsonValue));
    }

    /**
     * Change les avis d\'un participant
     */
    async meetingsParticipantsOpinionPartialUpdate(requestParameters: MeetingsParticipantsOpinionPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<OpinionParticipant> {
        const response = await this.meetingsParticipantsOpinionPartialUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Récupère les avis d\'un participants
     */
    async meetingsParticipantsOpinionRetrieveRaw(requestParameters: MeetingsParticipantsOpinionRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<OpinionParticipant>> {
        if (requestParameters['meetingUuid'] == null) {
            throw new runtime.RequiredError(
                'meetingUuid',
                'Required parameter "meetingUuid" was null or undefined when calling meetingsParticipantsOpinionRetrieve().'
            );
        }

        if (requestParameters['uuid'] == null) {
            throw new runtime.RequiredError(
                'uuid',
                'Required parameter "uuid" was null or undefined when calling meetingsParticipantsOpinionRetrieve().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // qrCodeTokenAuth authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // jwtAuth authentication
        }

        const response = await this.request({
            path: `/api/meetings/{meeting_uuid}/participants/{uuid}/opinion/`.replace(`{${"meeting_uuid"}}`, encodeURIComponent(String(requestParameters['meetingUuid']))).replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters['uuid']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OpinionParticipantFromJSON(jsonValue));
    }

    /**
     * Récupère les avis d\'un participants
     */
    async meetingsParticipantsOpinionRetrieve(requestParameters: MeetingsParticipantsOpinionRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<OpinionParticipant> {
        const response = await this.meetingsParticipantsOpinionRetrieveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Change les avis d\'un participant
     */
    async meetingsParticipantsOpinionUpdateRaw(requestParameters: MeetingsParticipantsOpinionUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<OpinionParticipant>> {
        if (requestParameters['meetingUuid'] == null) {
            throw new runtime.RequiredError(
                'meetingUuid',
                'Required parameter "meetingUuid" was null or undefined when calling meetingsParticipantsOpinionUpdate().'
            );
        }

        if (requestParameters['uuid'] == null) {
            throw new runtime.RequiredError(
                'uuid',
                'Required parameter "uuid" was null or undefined when calling meetingsParticipantsOpinionUpdate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // qrCodeTokenAuth authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // jwtAuth authentication
        }

        const response = await this.request({
            path: `/api/meetings/{meeting_uuid}/participants/{uuid}/opinion/`.replace(`{${"meeting_uuid"}}`, encodeURIComponent(String(requestParameters['meetingUuid']))).replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters['uuid']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: OpinionParticipantToJSON(requestParameters['opinionParticipant']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OpinionParticipantFromJSON(jsonValue));
    }

    /**
     * Change les avis d\'un participant
     */
    async meetingsParticipantsOpinionUpdate(requestParameters: MeetingsParticipantsOpinionUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<OpinionParticipant> {
        const response = await this.meetingsParticipantsOpinionUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Un \"MeetingParticipant\" correspond à une inscription à une action de formation ou d\'événement (Meeting)
     */
    async meetingsParticipantsPartialUpdateRaw(requestParameters: MeetingsParticipantsPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MeetingParticipant>> {
        if (requestParameters['meetingUuid'] == null) {
            throw new runtime.RequiredError(
                'meetingUuid',
                'Required parameter "meetingUuid" was null or undefined when calling meetingsParticipantsPartialUpdate().'
            );
        }

        if (requestParameters['uuid'] == null) {
            throw new runtime.RequiredError(
                'uuid',
                'Required parameter "uuid" was null or undefined when calling meetingsParticipantsPartialUpdate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // qrCodeTokenAuth authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // jwtAuth authentication
        }

        const response = await this.request({
            path: `/api/meetings/{meeting_uuid}/participants/{uuid}/`.replace(`{${"meeting_uuid"}}`, encodeURIComponent(String(requestParameters['meetingUuid']))).replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters['uuid']))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: PatchedMeetingParticipantToJSON(requestParameters['patchedMeetingParticipant']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MeetingParticipantFromJSON(jsonValue));
    }

    /**
     * Un \"MeetingParticipant\" correspond à une inscription à une action de formation ou d\'événement (Meeting)
     */
    async meetingsParticipantsPartialUpdate(requestParameters: MeetingsParticipantsPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MeetingParticipant> {
        const response = await this.meetingsParticipantsPartialUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Supprimer l\'avatar enregistrée
     */
    async meetingsParticipantsResetAvatarDestroyRaw(requestParameters: MeetingsParticipantsResetAvatarDestroyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['meetingUuid'] == null) {
            throw new runtime.RequiredError(
                'meetingUuid',
                'Required parameter "meetingUuid" was null or undefined when calling meetingsParticipantsResetAvatarDestroy().'
            );
        }

        if (requestParameters['uuid'] == null) {
            throw new runtime.RequiredError(
                'uuid',
                'Required parameter "uuid" was null or undefined when calling meetingsParticipantsResetAvatarDestroy().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // qrCodeTokenAuth authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // jwtAuth authentication
        }

        const response = await this.request({
            path: `/api/meetings/{meeting_uuid}/participants/{uuid}/reset-avatar/`.replace(`{${"meeting_uuid"}}`, encodeURIComponent(String(requestParameters['meetingUuid']))).replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters['uuid']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Supprimer l\'avatar enregistrée
     */
    async meetingsParticipantsResetAvatarDestroy(requestParameters: MeetingsParticipantsResetAvatarDestroyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.meetingsParticipantsResetAvatarDestroyRaw(requestParameters, initOverrides);
    }

    /**
     * Supprimer la signature enregistrée
     */
    async meetingsParticipantsResetSignatureDestroyRaw(requestParameters: MeetingsParticipantsResetSignatureDestroyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['meetingUuid'] == null) {
            throw new runtime.RequiredError(
                'meetingUuid',
                'Required parameter "meetingUuid" was null or undefined when calling meetingsParticipantsResetSignatureDestroy().'
            );
        }

        if (requestParameters['uuid'] == null) {
            throw new runtime.RequiredError(
                'uuid',
                'Required parameter "uuid" was null or undefined when calling meetingsParticipantsResetSignatureDestroy().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // qrCodeTokenAuth authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // jwtAuth authentication
        }

        const response = await this.request({
            path: `/api/meetings/{meeting_uuid}/participants/{uuid}/reset-signature/`.replace(`{${"meeting_uuid"}}`, encodeURIComponent(String(requestParameters['meetingUuid']))).replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters['uuid']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Supprimer la signature enregistrée
     */
    async meetingsParticipantsResetSignatureDestroy(requestParameters: MeetingsParticipantsResetSignatureDestroyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.meetingsParticipantsResetSignatureDestroyRaw(requestParameters, initOverrides);
    }

    /**
     * Récupère un participant par uuid
     */
    async meetingsParticipantsRetrieveRaw(requestParameters: MeetingsParticipantsRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MeetingParticipant>> {
        if (requestParameters['meetingUuid'] == null) {
            throw new runtime.RequiredError(
                'meetingUuid',
                'Required parameter "meetingUuid" was null or undefined when calling meetingsParticipantsRetrieve().'
            );
        }

        if (requestParameters['uuid'] == null) {
            throw new runtime.RequiredError(
                'uuid',
                'Required parameter "uuid" was null or undefined when calling meetingsParticipantsRetrieve().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // qrCodeTokenAuth authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // jwtAuth authentication
        }

        const response = await this.request({
            path: `/api/meetings/{meeting_uuid}/participants/{uuid}/`.replace(`{${"meeting_uuid"}}`, encodeURIComponent(String(requestParameters['meetingUuid']))).replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters['uuid']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MeetingParticipantFromJSON(jsonValue));
    }

    /**
     * Récupère un participant par uuid
     */
    async meetingsParticipantsRetrieve(requestParameters: MeetingsParticipantsRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MeetingParticipant> {
        const response = await this.meetingsParticipantsRetrieveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Un \"MeetingParticipant\" correspond à une inscription à une action de formation ou d\'événement (Meeting)
     */
    async meetingsParticipantsSignatureMediaRetrieveRaw(requestParameters: MeetingsParticipantsSignatureMediaRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MeetingParticipant>> {
        if (requestParameters['meetingUuid'] == null) {
            throw new runtime.RequiredError(
                'meetingUuid',
                'Required parameter "meetingUuid" was null or undefined when calling meetingsParticipantsSignatureMediaRetrieve().'
            );
        }

        if (requestParameters['uuid'] == null) {
            throw new runtime.RequiredError(
                'uuid',
                'Required parameter "uuid" was null or undefined when calling meetingsParticipantsSignatureMediaRetrieve().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // qrCodeTokenAuth authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // jwtAuth authentication
        }

        const response = await this.request({
            path: `/api/meetings/{meeting_uuid}/participants/{uuid}/signature-media/`.replace(`{${"meeting_uuid"}}`, encodeURIComponent(String(requestParameters['meetingUuid']))).replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters['uuid']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MeetingParticipantFromJSON(jsonValue));
    }

    /**
     * Un \"MeetingParticipant\" correspond à une inscription à une action de formation ou d\'événement (Meeting)
     */
    async meetingsParticipantsSignatureMediaRetrieve(requestParameters: MeetingsParticipantsSignatureMediaRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MeetingParticipant> {
        const response = await this.meetingsParticipantsSignatureMediaRetrieveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Un \"MeetingParticipant\" correspond à une inscription à une action de formation ou d\'événement (Meeting)
     */
    async meetingsParticipantsSignatureMediaUpdateRaw(requestParameters: MeetingsParticipantsSignatureMediaUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MeetingParticipant>> {
        if (requestParameters['meetingUuid'] == null) {
            throw new runtime.RequiredError(
                'meetingUuid',
                'Required parameter "meetingUuid" was null or undefined when calling meetingsParticipantsSignatureMediaUpdate().'
            );
        }

        if (requestParameters['uuid'] == null) {
            throw new runtime.RequiredError(
                'uuid',
                'Required parameter "uuid" was null or undefined when calling meetingsParticipantsSignatureMediaUpdate().'
            );
        }

        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling meetingsParticipantsSignatureMediaUpdate().'
            );
        }

        if (requestParameters['userId'] == null) {
            throw new runtime.RequiredError(
                'userId',
                'Required parameter "userId" was null or undefined when calling meetingsParticipantsSignatureMediaUpdate().'
            );
        }

        if (requestParameters['firstName'] == null) {
            throw new runtime.RequiredError(
                'firstName',
                'Required parameter "firstName" was null or undefined when calling meetingsParticipantsSignatureMediaUpdate().'
            );
        }

        if (requestParameters['lastName'] == null) {
            throw new runtime.RequiredError(
                'lastName',
                'Required parameter "lastName" was null or undefined when calling meetingsParticipantsSignatureMediaUpdate().'
            );
        }

        if (requestParameters['usageFirstName'] == null) {
            throw new runtime.RequiredError(
                'usageFirstName',
                'Required parameter "usageFirstName" was null or undefined when calling meetingsParticipantsSignatureMediaUpdate().'
            );
        }

        if (requestParameters['usageLastName'] == null) {
            throw new runtime.RequiredError(
                'usageLastName',
                'Required parameter "usageLastName" was null or undefined when calling meetingsParticipantsSignatureMediaUpdate().'
            );
        }

        if (requestParameters['created'] == null) {
            throw new runtime.RequiredError(
                'created',
                'Required parameter "created" was null or undefined when calling meetingsParticipantsSignatureMediaUpdate().'
            );
        }

        if (requestParameters['updated'] == null) {
            throw new runtime.RequiredError(
                'updated',
                'Required parameter "updated" was null or undefined when calling meetingsParticipantsSignatureMediaUpdate().'
            );
        }

        if (requestParameters['meeting'] == null) {
            throw new runtime.RequiredError(
                'meeting',
                'Required parameter "meeting" was null or undefined when calling meetingsParticipantsSignatureMediaUpdate().'
            );
        }

        if (requestParameters['user'] == null) {
            throw new runtime.RequiredError(
                'user',
                'Required parameter "user" was null or undefined when calling meetingsParticipantsSignatureMediaUpdate().'
            );
        }

        if (requestParameters['role'] == null) {
            throw new runtime.RequiredError(
                'role',
                'Required parameter "role" was null or undefined when calling meetingsParticipantsSignatureMediaUpdate().'
            );
        }

        if (requestParameters['dailyTaskTeam'] == null) {
            throw new runtime.RequiredError(
                'dailyTaskTeam',
                'Required parameter "dailyTaskTeam" was null or undefined when calling meetingsParticipantsSignatureMediaUpdate().'
            );
        }

        if (requestParameters['teams'] == null) {
            throw new runtime.RequiredError(
                'teams',
                'Required parameter "teams" was null or undefined when calling meetingsParticipantsSignatureMediaUpdate().'
            );
        }

        if (requestParameters['fonction'] == null) {
            throw new runtime.RequiredError(
                'fonction',
                'Required parameter "fonction" was null or undefined when calling meetingsParticipantsSignatureMediaUpdate().'
            );
        }

        if (requestParameters['structurePrincipale'] == null) {
            throw new runtime.RequiredError(
                'structurePrincipale',
                'Required parameter "structurePrincipale" was null or undefined when calling meetingsParticipantsSignatureMediaUpdate().'
            );
        }

        if (requestParameters['track'] == null) {
            throw new runtime.RequiredError(
                'track',
                'Required parameter "track" was null or undefined when calling meetingsParticipantsSignatureMediaUpdate().'
            );
        }

        if (requestParameters['canceled'] == null) {
            throw new runtime.RequiredError(
                'canceled',
                'Required parameter "canceled" was null or undefined when calling meetingsParticipantsSignatureMediaUpdate().'
            );
        }

        if (requestParameters['observationCount'] == null) {
            throw new runtime.RequiredError(
                'observationCount',
                'Required parameter "observationCount" was null or undefined when calling meetingsParticipantsSignatureMediaUpdate().'
            );
        }

        if (requestParameters['trackId'] == null) {
            throw new runtime.RequiredError(
                'trackId',
                'Required parameter "trackId" was null or undefined when calling meetingsParticipantsSignatureMediaUpdate().'
            );
        }

        if (requestParameters['lastSync'] == null) {
            throw new runtime.RequiredError(
                'lastSync',
                'Required parameter "lastSync" was null or undefined when calling meetingsParticipantsSignatureMediaUpdate().'
            );
        }

        if (requestParameters['tagadaSyncObject'] == null) {
            throw new runtime.RequiredError(
                'tagadaSyncObject',
                'Required parameter "tagadaSyncObject" was null or undefined when calling meetingsParticipantsSignatureMediaUpdate().'
            );
        }

        if (requestParameters['avisPrevalidation'] == null) {
            throw new runtime.RequiredError(
                'avisPrevalidation',
                'Required parameter "avisPrevalidation" was null or undefined when calling meetingsParticipantsSignatureMediaUpdate().'
            );
        }

        if (requestParameters['avisValidation'] == null) {
            throw new runtime.RequiredError(
                'avisValidation',
                'Required parameter "avisValidation" was null or undefined when calling meetingsParticipantsSignatureMediaUpdate().'
            );
        }

        if (requestParameters['avisValidationGestionnaire'] == null) {
            throw new runtime.RequiredError(
                'avisValidationGestionnaire',
                'Required parameter "avisValidationGestionnaire" was null or undefined when calling meetingsParticipantsSignatureMediaUpdate().'
            );
        }

        if (requestParameters['signatureUrl'] == null) {
            throw new runtime.RequiredError(
                'signatureUrl',
                'Required parameter "signatureUrl" was null or undefined when calling meetingsParticipantsSignatureMediaUpdate().'
            );
        }

        if (requestParameters['signatureAvailable'] == null) {
            throw new runtime.RequiredError(
                'signatureAvailable',
                'Required parameter "signatureAvailable" was null or undefined when calling meetingsParticipantsSignatureMediaUpdate().'
            );
        }

        if (requestParameters['avatarUrl'] == null) {
            throw new runtime.RequiredError(
                'avatarUrl',
                'Required parameter "avatarUrl" was null or undefined when calling meetingsParticipantsSignatureMediaUpdate().'
            );
        }

        if (requestParameters['avatarAvailable'] == null) {
            throw new runtime.RequiredError(
                'avatarAvailable',
                'Required parameter "avatarAvailable" was null or undefined when calling meetingsParticipantsSignatureMediaUpdate().'
            );
        }

        if (requestParameters['userAvatarUrl'] == null) {
            throw new runtime.RequiredError(
                'userAvatarUrl',
                'Required parameter "userAvatarUrl" was null or undefined when calling meetingsParticipantsSignatureMediaUpdate().'
            );
        }

        if (requestParameters['userAvatarAvailable'] == null) {
            throw new runtime.RequiredError(
                'userAvatarAvailable',
                'Required parameter "userAvatarAvailable" was null or undefined when calling meetingsParticipantsSignatureMediaUpdate().'
            );
        }

        if (requestParameters['userLastSync'] == null) {
            throw new runtime.RequiredError(
                'userLastSync',
                'Required parameter "userLastSync" was null or undefined when calling meetingsParticipantsSignatureMediaUpdate().'
            );
        }

        if (requestParameters['rgUser'] == null) {
            throw new runtime.RequiredError(
                'rgUser',
                'Required parameter "rgUser" was null or undefined when calling meetingsParticipantsSignatureMediaUpdate().'
            );
        }

        if (requestParameters['dtUser'] == null) {
            throw new runtime.RequiredError(
                'dtUser',
                'Required parameter "dtUser" was null or undefined when calling meetingsParticipantsSignatureMediaUpdate().'
            );
        }

        if (requestParameters['gender'] == null) {
            throw new runtime.RequiredError(
                'gender',
                'Required parameter "gender" was null or undefined when calling meetingsParticipantsSignatureMediaUpdate().'
            );
        }

        if (requestParameters['birthName'] == null) {
            throw new runtime.RequiredError(
                'birthName',
                'Required parameter "birthName" was null or undefined when calling meetingsParticipantsSignatureMediaUpdate().'
            );
        }

        if (requestParameters['whichAdministrativeLastname'] == null) {
            throw new runtime.RequiredError(
                'whichAdministrativeLastname',
                'Required parameter "whichAdministrativeLastname" was null or undefined when calling meetingsParticipantsSignatureMediaUpdate().'
            );
        }

        if (requestParameters['whichAdministrativeFirstname'] == null) {
            throw new runtime.RequiredError(
                'whichAdministrativeFirstname',
                'Required parameter "whichAdministrativeFirstname" was null or undefined when calling meetingsParticipantsSignatureMediaUpdate().'
            );
        }

        if (requestParameters['phone'] == null) {
            throw new runtime.RequiredError(
                'phone',
                'Required parameter "phone" was null or undefined when calling meetingsParticipantsSignatureMediaUpdate().'
            );
        }

        if (requestParameters['email'] == null) {
            throw new runtime.RequiredError(
                'email',
                'Required parameter "email" was null or undefined when calling meetingsParticipantsSignatureMediaUpdate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // qrCodeTokenAuth authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // jwtAuth authentication
        }

        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters['id'] != null) {
            formParams.append('id', requestParameters['id'] as any);
        }

        if (requestParameters['userId'] != null) {
            formParams.append('user_id', requestParameters['userId'] as any);
        }

        if (requestParameters['firstName'] != null) {
            formParams.append('first_name', requestParameters['firstName'] as any);
        }

        if (requestParameters['lastName'] != null) {
            formParams.append('last_name', requestParameters['lastName'] as any);
        }

        if (requestParameters['usageFirstName'] != null) {
            formParams.append('usage_first_name', requestParameters['usageFirstName'] as any);
        }

        if (requestParameters['usageLastName'] != null) {
            formParams.append('usage_last_name', requestParameters['usageLastName'] as any);
        }

        if (requestParameters['created'] != null) {
            formParams.append('created', (requestParameters['created'] as any).toISOString());
        }

        if (requestParameters['updated'] != null) {
            formParams.append('updated', (requestParameters['updated'] as any).toISOString());
        }

        if (requestParameters['meeting'] != null) {
            formParams.append('meeting', requestParameters['meeting'] as any);
        }

        if (requestParameters['user'] != null) {
            formParams.append('user', new Blob([JSON.stringify(NotNestedUserToJSON(requestParameters['user']))], { type: "application/json", }));
                    }

        if (requestParameters['role'] != null) {
            formParams.append('role', new Blob([JSON.stringify(NotNestedMeetingRoleToJSON(requestParameters['role']))], { type: "application/json", }));
                    }

        if (requestParameters['dailyTaskTeam'] != null) {
            formParams.append('daily_task_team', new Blob([JSON.stringify(NotNestedDailyTaskTeamToJSON(requestParameters['dailyTaskTeam']))], { type: "application/json", }));
                    }

        if (requestParameters['teams'] != null) {
            formParams.append('teams', requestParameters['teams']!.join(runtime.COLLECTION_FORMATS["csv"]));
        }

        if (requestParameters['fonction'] != null) {
            formParams.append('fonction', requestParameters['fonction'] as any);
        }

        if (requestParameters['structurePrincipale'] != null) {
            formParams.append('structure_principale', new Blob([JSON.stringify(ReadonlyStructureToJSON(requestParameters['structurePrincipale']))], { type: "application/json", }));
                    }

        if (requestParameters['branche'] != null) {
            formParams.append('branche', requestParameters['branche'] as any);
        }

        if (requestParameters['track'] != null) {
            formParams.append('track', new Blob([JSON.stringify(TrackToJSON(requestParameters['track']))], { type: "application/json", }));
                    }

        if (requestParameters['numBafaBafd'] != null) {
            formParams.append('num_bafa_bafd', requestParameters['numBafaBafd'] as any);
        }

        if (requestParameters['foodRegime'] != null) {
            formParams.append('food_regime', requestParameters['foodRegime'] as any);
        }

        if (requestParameters['sessionStatus'] != null) {
            formParams.append('session_status', requestParameters['sessionStatus'] as any);
        }

        if (requestParameters['complementInformation'] != null) {
            formParams.append('complement_information', requestParameters['complementInformation'] as any);
        }

        if (requestParameters['canceled'] != null) {
            formParams.append('canceled', requestParameters['canceled'] as any);
        }

        if (requestParameters['hidden'] != null) {
            formParams.append('hidden', requestParameters['hidden'] as any);
        }

        if (requestParameters['observationCount'] != null) {
            formParams.append('observation_count', requestParameters['observationCount'] as any);
        }

        if (requestParameters['birthdate'] != null) {
            formParams.append('birthdate', requestParameters['birthdate'] as any);
        }

        if (requestParameters['hebergement'] != null) {
            formParams.append('hebergement', requestParameters['hebergement'] as any);
        }

        if (requestParameters['trackId'] != null) {
            formParams.append('track_id', requestParameters['trackId'] as any);
        }

        if (requestParameters['lastSync'] != null) {
            formParams.append('last_sync', (requestParameters['lastSync'] as any).toISOString());
        }

        if (requestParameters['isBafaBafdCandidate'] != null) {
            formParams.append('is_bafa_bafd_candidate', requestParameters['isBafaBafdCandidate'] as any);
        }

        if (requestParameters['tagadaCandidateStatus'] != null) {
            formParams.append('tagada_candidate_status', requestParameters['tagadaCandidateStatus'] as any);
        }

        if (requestParameters['tagadaAvisStatus'] != null) {
            formParams.append('tagada_avis_status', requestParameters['tagadaAvisStatus'] as any);
        }

        if (requestParameters['tagadaAvisLastSync'] != null) {
            formParams.append('tagada_avis_last_sync', (requestParameters['tagadaAvisLastSync'] as any).toISOString());
        }

        if (requestParameters['tagadaSyncObject'] != null) {
            formParams.append('tagada_sync_object', requestParameters['tagadaSyncObject'] as any);
        }

        if (requestParameters['ageAtMeetingStart'] != null) {
            formParams.append('age_at_meeting_start', requestParameters['ageAtMeetingStart'] as any);
        }

        if (requestParameters['startInterview'] != null) {
            formParams.append('start_interview', requestParameters['startInterview'] as any);
        }

        if (requestParameters['middleInterview'] != null) {
            formParams.append('middle_interview', requestParameters['middleInterview'] as any);
        }

        if (requestParameters['endInterview'] != null) {
            formParams.append('end_interview', requestParameters['endInterview'] as any);
        }

        if (requestParameters['sgdfOpinion'] != null) {
            formParams.append('sgdf_opinion', requestParameters['sgdfOpinion'] as any);
        }

        if (requestParameters['jsOpinion'] != null) {
            formParams.append('js_opinion', requestParameters['jsOpinion'] as any);
        }

        if (requestParameters['avisPrevalidation'] != null) {
            formParams.append('avis_prevalidation', requestParameters['avisPrevalidation'] as any);
        }

        if (requestParameters['avisValidation'] != null) {
            formParams.append('avis_validation', requestParameters['avisValidation'] as any);
        }

        if (requestParameters['avisValidationGestionnaire'] != null) {
            formParams.append('avis_validation_gestionnaire', requestParameters['avisValidationGestionnaire'] as any);
        }

        if (requestParameters['signatureUrl'] != null) {
            formParams.append('signature_url', requestParameters['signatureUrl'] as any);
        }

        if (requestParameters['signatureAvailable'] != null) {
            formParams.append('signature_available', requestParameters['signatureAvailable'] as any);
        }

        if (requestParameters['avatarUrl'] != null) {
            formParams.append('avatar_url', requestParameters['avatarUrl'] as any);
        }

        if (requestParameters['avatarAvailable'] != null) {
            formParams.append('avatar_available', requestParameters['avatarAvailable'] as any);
        }

        if (requestParameters['userAvatarUrl'] != null) {
            formParams.append('user_avatar_url', requestParameters['userAvatarUrl'] as any);
        }

        if (requestParameters['userAvatarAvailable'] != null) {
            formParams.append('user_avatar_available', requestParameters['userAvatarAvailable'] as any);
        }

        if (requestParameters['userLastSync'] != null) {
            formParams.append('user_last_sync', (requestParameters['userLastSync'] as any).toISOString());
        }

        if (requestParameters['rgUser'] != null) {
            formParams.append('rg_user', new Blob([JSON.stringify(NotNestedUserToJSON(requestParameters['rgUser']))], { type: "application/json", }));
                    }

        if (requestParameters['dtUser'] != null) {
            formParams.append('dt_user', new Blob([JSON.stringify(NotNestedUserToJSON(requestParameters['dtUser']))], { type: "application/json", }));
                    }

        if (requestParameters['gender'] != null) {
            formParams.append('gender', requestParameters['gender'] as any);
        }

        if (requestParameters['birthName'] != null) {
            formParams.append('birth_name', requestParameters['birthName'] as any);
        }

        if (requestParameters['whichAdministrativeLastname'] != null) {
            formParams.append('which_administrative_lastname', requestParameters['whichAdministrativeLastname'] as any);
        }

        if (requestParameters['whichAdministrativeFirstname'] != null) {
            formParams.append('which_administrative_firstname', requestParameters['whichAdministrativeFirstname'] as any);
        }

        if (requestParameters['phone'] != null) {
            formParams.append('phone', requestParameters['phone'] as any);
        }

        if (requestParameters['email'] != null) {
            formParams.append('email', requestParameters['email'] as any);
        }

        const response = await this.request({
            path: `/api/meetings/{meeting_uuid}/participants/{uuid}/signature-media/`.replace(`{${"meeting_uuid"}}`, encodeURIComponent(String(requestParameters['meetingUuid']))).replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters['uuid']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MeetingParticipantFromJSON(jsonValue));
    }

    /**
     * Un \"MeetingParticipant\" correspond à une inscription à une action de formation ou d\'événement (Meeting)
     */
    async meetingsParticipantsSignatureMediaUpdate(requestParameters: MeetingsParticipantsSignatureMediaUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MeetingParticipant> {
        const response = await this.meetingsParticipantsSignatureMediaUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Un \"MeetingParticipant\" correspond à une inscription à une action de formation ou d\'événement (Meeting)
     */
    async meetingsParticipantsUpdateRaw(requestParameters: MeetingsParticipantsUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MeetingParticipant>> {
        if (requestParameters['meetingUuid'] == null) {
            throw new runtime.RequiredError(
                'meetingUuid',
                'Required parameter "meetingUuid" was null or undefined when calling meetingsParticipantsUpdate().'
            );
        }

        if (requestParameters['uuid'] == null) {
            throw new runtime.RequiredError(
                'uuid',
                'Required parameter "uuid" was null or undefined when calling meetingsParticipantsUpdate().'
            );
        }

        if (requestParameters['meetingParticipant'] == null) {
            throw new runtime.RequiredError(
                'meetingParticipant',
                'Required parameter "meetingParticipant" was null or undefined when calling meetingsParticipantsUpdate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // qrCodeTokenAuth authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // jwtAuth authentication
        }

        const response = await this.request({
            path: `/api/meetings/{meeting_uuid}/participants/{uuid}/`.replace(`{${"meeting_uuid"}}`, encodeURIComponent(String(requestParameters['meetingUuid']))).replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters['uuid']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: MeetingParticipantToJSON(requestParameters['meetingParticipant']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MeetingParticipantFromJSON(jsonValue));
    }

    /**
     * Un \"MeetingParticipant\" correspond à une inscription à une action de formation ou d\'événement (Meeting)
     */
    async meetingsParticipantsUpdate(requestParameters: MeetingsParticipantsUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MeetingParticipant> {
        const response = await this.meetingsParticipantsUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
