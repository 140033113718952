import revive_payload_client_3MXxeBhr6g from "/app/common/temp/node_modules/.pnpm/nuxt@3.15.0_@parcel+watcher@2.5.0_@types+node@22.9.0_db0@0.2.1_eslint@9.14.0_jiti@2.4.2__iore_7k2zwalix74upbfrkp74sdzniu/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_U9QzerdSZ2 from "/app/common/temp/node_modules/.pnpm/nuxt@3.15.0_@parcel+watcher@2.5.0_@types+node@22.9.0_db0@0.2.1_eslint@9.14.0_jiti@2.4.2__iore_7k2zwalix74upbfrkp74sdzniu/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_yHEGztS3MC from "/app/common/temp/node_modules/.pnpm/nuxt@3.15.0_@parcel+watcher@2.5.0_@types+node@22.9.0_db0@0.2.1_eslint@9.14.0_jiti@2.4.2__iore_7k2zwalix74upbfrkp74sdzniu/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_X3gmRRWDzm from "/app/common/temp/node_modules/.pnpm/nuxt@3.15.0_@parcel+watcher@2.5.0_@types+node@22.9.0_db0@0.2.1_eslint@9.14.0_jiti@2.4.2__iore_7k2zwalix74upbfrkp74sdzniu/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_BPgMcr0oMW from "/app/common/temp/node_modules/.pnpm/nuxt@3.15.0_@parcel+watcher@2.5.0_@types+node@22.9.0_db0@0.2.1_eslint@9.14.0_jiti@2.4.2__iore_7k2zwalix74upbfrkp74sdzniu/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_mGKuhWJ7tN from "/app/common/temp/node_modules/.pnpm/nuxt@3.15.0_@parcel+watcher@2.5.0_@types+node@22.9.0_db0@0.2.1_eslint@9.14.0_jiti@2.4.2__iore_7k2zwalix74upbfrkp74sdzniu/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_z7DjqmThvH from "/app/common/temp/node_modules/.pnpm/nuxt@3.15.0_@parcel+watcher@2.5.0_@types+node@22.9.0_db0@0.2.1_eslint@9.14.0_jiti@2.4.2__iore_7k2zwalix74upbfrkp74sdzniu/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_5nMByfh5mM from "/app/common/temp/node_modules/.pnpm/@pinia+nuxt@0.5.1_magicast@0.3.5_rollup@4.25.0_typescript@5.6.3_vue@3.5.12_typescript@5.6.3_/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/app/webapp/.nuxt/components.plugin.mjs";
import prefetch_client_6mkBtB2XXF from "/app/common/temp/node_modules/.pnpm/nuxt@3.15.0_@parcel+watcher@2.5.0_@types+node@22.9.0_db0@0.2.1_eslint@9.14.0_jiti@2.4.2__iore_7k2zwalix74upbfrkp74sdzniu/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_ftz8M5wp9R from "/app/common/temp/node_modules/.pnpm/pinia-plugin-persistedstate@4.1.3_@pinia+nuxt@0.5.1_magicast@0.3.5_typescript@5.6.3_vue@3.5.1_mb4ocpku6z7x3d4szmexrkwg5y/node_modules/pinia-plugin-persistedstate/dist/nuxt/runtime/plugin.js";
import api_service_XSI3K6oOsN from "/app/webapp/plugins/api.service.ts";
import error_ldt3PQauZ0 from "/app/webapp/plugins/error.ts";
import i18n_VfGcjrvSkj from "/app/webapp/plugins/i18n.ts";
import internet_connection_qmNk5ogxp5 from "/app/webapp/plugins/internet-connection.ts";
import sentry_3AyO8nEfhE from "/app/webapp/plugins/sentry.ts";
import vue_query_wrmMkNpEpe from "/app/webapp/plugins/vue-query.ts";
export default [
  revive_payload_client_3MXxeBhr6g,
  unhead_U9QzerdSZ2,
  router_yHEGztS3MC,
  payload_client_X3gmRRWDzm,
  navigation_repaint_client_BPgMcr0oMW,
  check_outdated_build_client_mGKuhWJ7tN,
  chunk_reload_client_z7DjqmThvH,
  plugin_vue3_5nMByfh5mM,
  components_plugin_KR1HBZs4kY,
  prefetch_client_6mkBtB2XXF,
  plugin_ftz8M5wp9R,
  api_service_XSI3K6oOsN,
  error_ldt3PQauZ0,
  i18n_VfGcjrvSkj,
  internet_connection_qmNk5ogxp5,
  sentry_3AyO8nEfhE,
  vue_query_wrmMkNpEpe
]