/* tslint:disable */
/* eslint-disable */
/**
 * Digiform API
 * Digiform API pour les SGDF
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  DiplomaTypeEnum,
  GenderEnum,
  NotNestedDiploma,
  PatchedNotNestedDiploma,
  ReadOnlyNotNestedUser,
  SGDFUserDetails,
  UserAccessRights,
} from '../models/index';
import {
    DiplomaTypeEnumFromJSON,
    DiplomaTypeEnumToJSON,
    GenderEnumFromJSON,
    GenderEnumToJSON,
    NotNestedDiplomaFromJSON,
    NotNestedDiplomaToJSON,
    PatchedNotNestedDiplomaFromJSON,
    PatchedNotNestedDiplomaToJSON,
    ReadOnlyNotNestedUserFromJSON,
    ReadOnlyNotNestedUserToJSON,
    SGDFUserDetailsFromJSON,
    SGDFUserDetailsToJSON,
    UserAccessRightsFromJSON,
    UserAccessRightsToJSON,
} from '../models/index';

export interface UsersAccessRightsRetrieveRequest {
    uuid: string;
}

export interface UsersAvatarMediaRetrieveRequest {
    uuid: string;
}

export interface UsersAvatarMediaUpdateRequest {
    uuid: string;
    id: string;
    username: string;
    gender: GenderEnum;
    firstName: string;
    lastName: string;
    birthName: string | null;
    usageFirstName: string | null;
    usageLastName: string | null;
    phone: string;
    birthdate: Date | null;
    email: string;
    fonction: string | null;
    fonctionCode: string | null;
    structure: string | null;
    diplomas: Array<NotNestedDiploma>;
    lastSync: Date | null;
}

export interface UsersDiplomasCreateRequest {
    userUuid: string;
    uuid: string;
    notNestedDiploma: Omit<NotNestedDiploma, 'id'|'diploma_url'|'diploma_available'>;
}

export interface UsersDiplomasDestroyRequest {
    userUuid: string;
    uuid: string;
}

export interface UsersDiplomasDiplomaMediaRetrieveRequest {
    userUuid: string;
    uuid: string;
}

export interface UsersDiplomasDiplomaMediaUpdateRequest {
    userUuid: string;
    uuid: string;
    id: number;
    diplomaUrl: string;
    diplomaType: DiplomaTypeEnum;
    issueDate: Date;
    diplomaAvailable: boolean;
}

export interface UsersDiplomasListRequest {
    userUuid: string;
    uuid: string;
    ordering?: string;
    search?: string;
}

export interface UsersDiplomasPartialUpdateRequest {
    userUuid: string;
    uuid: string;
    patchedNotNestedDiploma?: Omit<PatchedNotNestedDiploma, 'id'|'diploma_url'|'diploma_available'>;
}

export interface UsersDiplomasRetrieveRequest {
    userUuid: string;
    uuid: string;
}

export interface UsersDiplomasUpdateRequest {
    userUuid: string;
    uuid: string;
    notNestedDiploma: Omit<NotNestedDiploma, 'id'|'diploma_url'|'diploma_available'>;
}

export interface UsersMeRetrieveRequest {
    uuid: string;
}

export interface UsersResetAvatarDestroyRequest {
    uuid: string;
}

export interface UsersResetSignatureDestroyRequest {
    uuid: string;
}

export interface UsersRetrieveRequest {
    uuid: string;
}

export interface UsersSignatureMediaRetrieveRequest {
    uuid: string;
}

export interface UsersSignatureMediaUpdateRequest {
    uuid: string;
    id: string;
    username: string;
    gender: GenderEnum;
    firstName: string;
    lastName: string;
    birthName: string | null;
    usageFirstName: string | null;
    usageLastName: string | null;
    phone: string;
    birthdate: Date | null;
    email: string;
    fonction: string | null;
    fonctionCode: string | null;
    structure: string | null;
    diplomas: Array<NotNestedDiploma>;
    lastSync: Date | null;
}

/**
 * 
 */
export class UsersApi extends runtime.BaseAPI {

    /**
     * La liste des utilisateurs
     */
    async usersAccessRightsRetrieveRaw(requestParameters: UsersAccessRightsRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UserAccessRights>> {
        if (requestParameters['uuid'] == null) {
            throw new runtime.RequiredError(
                'uuid',
                'Required parameter "uuid" was null or undefined when calling usersAccessRightsRetrieve().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // qrCodeTokenAuth authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // jwtAuth authentication
        }

        const response = await this.request({
            path: `/api/users/access-rights/`.replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters['uuid']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserAccessRightsFromJSON(jsonValue));
    }

    /**
     * La liste des utilisateurs
     */
    async usersAccessRightsRetrieve(requestParameters: UsersAccessRightsRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UserAccessRights> {
        const response = await this.usersAccessRightsRetrieveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * La liste des utilisateurs
     */
    async usersAvatarMediaRetrieveRaw(requestParameters: UsersAvatarMediaRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ReadOnlyNotNestedUser>> {
        if (requestParameters['uuid'] == null) {
            throw new runtime.RequiredError(
                'uuid',
                'Required parameter "uuid" was null or undefined when calling usersAvatarMediaRetrieve().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // qrCodeTokenAuth authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // jwtAuth authentication
        }

        const response = await this.request({
            path: `/api/users/{uuid}/avatar-media/`.replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters['uuid']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ReadOnlyNotNestedUserFromJSON(jsonValue));
    }

    /**
     * La liste des utilisateurs
     */
    async usersAvatarMediaRetrieve(requestParameters: UsersAvatarMediaRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ReadOnlyNotNestedUser> {
        const response = await this.usersAvatarMediaRetrieveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * La liste des utilisateurs
     */
    async usersAvatarMediaUpdateRaw(requestParameters: UsersAvatarMediaUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ReadOnlyNotNestedUser>> {
        if (requestParameters['uuid'] == null) {
            throw new runtime.RequiredError(
                'uuid',
                'Required parameter "uuid" was null or undefined when calling usersAvatarMediaUpdate().'
            );
        }

        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling usersAvatarMediaUpdate().'
            );
        }

        if (requestParameters['username'] == null) {
            throw new runtime.RequiredError(
                'username',
                'Required parameter "username" was null or undefined when calling usersAvatarMediaUpdate().'
            );
        }

        if (requestParameters['gender'] == null) {
            throw new runtime.RequiredError(
                'gender',
                'Required parameter "gender" was null or undefined when calling usersAvatarMediaUpdate().'
            );
        }

        if (requestParameters['firstName'] == null) {
            throw new runtime.RequiredError(
                'firstName',
                'Required parameter "firstName" was null or undefined when calling usersAvatarMediaUpdate().'
            );
        }

        if (requestParameters['lastName'] == null) {
            throw new runtime.RequiredError(
                'lastName',
                'Required parameter "lastName" was null or undefined when calling usersAvatarMediaUpdate().'
            );
        }

        if (requestParameters['birthName'] == null) {
            throw new runtime.RequiredError(
                'birthName',
                'Required parameter "birthName" was null or undefined when calling usersAvatarMediaUpdate().'
            );
        }

        if (requestParameters['usageFirstName'] == null) {
            throw new runtime.RequiredError(
                'usageFirstName',
                'Required parameter "usageFirstName" was null or undefined when calling usersAvatarMediaUpdate().'
            );
        }

        if (requestParameters['usageLastName'] == null) {
            throw new runtime.RequiredError(
                'usageLastName',
                'Required parameter "usageLastName" was null or undefined when calling usersAvatarMediaUpdate().'
            );
        }

        if (requestParameters['phone'] == null) {
            throw new runtime.RequiredError(
                'phone',
                'Required parameter "phone" was null or undefined when calling usersAvatarMediaUpdate().'
            );
        }

        if (requestParameters['birthdate'] == null) {
            throw new runtime.RequiredError(
                'birthdate',
                'Required parameter "birthdate" was null or undefined when calling usersAvatarMediaUpdate().'
            );
        }

        if (requestParameters['email'] == null) {
            throw new runtime.RequiredError(
                'email',
                'Required parameter "email" was null or undefined when calling usersAvatarMediaUpdate().'
            );
        }

        if (requestParameters['fonction'] == null) {
            throw new runtime.RequiredError(
                'fonction',
                'Required parameter "fonction" was null or undefined when calling usersAvatarMediaUpdate().'
            );
        }

        if (requestParameters['fonctionCode'] == null) {
            throw new runtime.RequiredError(
                'fonctionCode',
                'Required parameter "fonctionCode" was null or undefined when calling usersAvatarMediaUpdate().'
            );
        }

        if (requestParameters['structure'] == null) {
            throw new runtime.RequiredError(
                'structure',
                'Required parameter "structure" was null or undefined when calling usersAvatarMediaUpdate().'
            );
        }

        if (requestParameters['diplomas'] == null) {
            throw new runtime.RequiredError(
                'diplomas',
                'Required parameter "diplomas" was null or undefined when calling usersAvatarMediaUpdate().'
            );
        }

        if (requestParameters['lastSync'] == null) {
            throw new runtime.RequiredError(
                'lastSync',
                'Required parameter "lastSync" was null or undefined when calling usersAvatarMediaUpdate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // qrCodeTokenAuth authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // jwtAuth authentication
        }

        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters['id'] != null) {
            formParams.append('id', requestParameters['id'] as any);
        }

        if (requestParameters['username'] != null) {
            formParams.append('username', requestParameters['username'] as any);
        }

        if (requestParameters['gender'] != null) {
            formParams.append('gender', requestParameters['gender'] as any);
        }

        if (requestParameters['firstName'] != null) {
            formParams.append('first_name', requestParameters['firstName'] as any);
        }

        if (requestParameters['lastName'] != null) {
            formParams.append('last_name', requestParameters['lastName'] as any);
        }

        if (requestParameters['birthName'] != null) {
            formParams.append('birth_name', requestParameters['birthName'] as any);
        }

        if (requestParameters['usageFirstName'] != null) {
            formParams.append('usage_first_name', requestParameters['usageFirstName'] as any);
        }

        if (requestParameters['usageLastName'] != null) {
            formParams.append('usage_last_name', requestParameters['usageLastName'] as any);
        }

        if (requestParameters['phone'] != null) {
            formParams.append('phone', requestParameters['phone'] as any);
        }

        if (requestParameters['birthdate'] != null) {
            formParams.append('birthdate', requestParameters['birthdate'] as any);
        }

        if (requestParameters['email'] != null) {
            formParams.append('email', requestParameters['email'] as any);
        }

        if (requestParameters['fonction'] != null) {
            formParams.append('fonction', requestParameters['fonction'] as any);
        }

        if (requestParameters['fonctionCode'] != null) {
            formParams.append('fonction_code', requestParameters['fonctionCode'] as any);
        }

        if (requestParameters['structure'] != null) {
            formParams.append('structure', requestParameters['structure'] as any);
        }

        if (requestParameters['diplomas'] != null) {
            formParams.append('diplomas', requestParameters['diplomas']!.join(runtime.COLLECTION_FORMATS["csv"]));
        }

        if (requestParameters['lastSync'] != null) {
            formParams.append('last_sync', (requestParameters['lastSync'] as any).toISOString());
        }

        const response = await this.request({
            path: `/api/users/{uuid}/avatar-media/`.replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters['uuid']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ReadOnlyNotNestedUserFromJSON(jsonValue));
    }

    /**
     * La liste des utilisateurs
     */
    async usersAvatarMediaUpdate(requestParameters: UsersAvatarMediaUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ReadOnlyNotNestedUser> {
        const response = await this.usersAvatarMediaUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create a new diploma for the user
     */
    async usersDiplomasCreateRaw(requestParameters: UsersDiplomasCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<NotNestedDiploma>> {
        if (requestParameters['userUuid'] == null) {
            throw new runtime.RequiredError(
                'userUuid',
                'Required parameter "userUuid" was null or undefined when calling usersDiplomasCreate().'
            );
        }

        if (requestParameters['uuid'] == null) {
            throw new runtime.RequiredError(
                'uuid',
                'Required parameter "uuid" was null or undefined when calling usersDiplomasCreate().'
            );
        }

        if (requestParameters['notNestedDiploma'] == null) {
            throw new runtime.RequiredError(
                'notNestedDiploma',
                'Required parameter "notNestedDiploma" was null or undefined when calling usersDiplomasCreate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // qrCodeTokenAuth authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // jwtAuth authentication
        }

        const response = await this.request({
            path: `/api/users/{user_uuid}/diplomas/`.replace(`{${"user_uuid"}}`, encodeURIComponent(String(requestParameters['userUuid']))).replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters['uuid']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: NotNestedDiplomaToJSON(requestParameters['notNestedDiploma']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => NotNestedDiplomaFromJSON(jsonValue));
    }

    /**
     * Create a new diploma for the user
     */
    async usersDiplomasCreate(requestParameters: UsersDiplomasCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<NotNestedDiploma> {
        const response = await this.usersDiplomasCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Delete a diploma
     */
    async usersDiplomasDestroyRaw(requestParameters: UsersDiplomasDestroyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['userUuid'] == null) {
            throw new runtime.RequiredError(
                'userUuid',
                'Required parameter "userUuid" was null or undefined when calling usersDiplomasDestroy().'
            );
        }

        if (requestParameters['uuid'] == null) {
            throw new runtime.RequiredError(
                'uuid',
                'Required parameter "uuid" was null or undefined when calling usersDiplomasDestroy().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // qrCodeTokenAuth authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // jwtAuth authentication
        }

        const response = await this.request({
            path: `/api/users/{user_uuid}/diplomas/{uuid}/`.replace(`{${"user_uuid"}}`, encodeURIComponent(String(requestParameters['userUuid']))).replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters['uuid']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete a diploma
     */
    async usersDiplomasDestroy(requestParameters: UsersDiplomasDestroyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.usersDiplomasDestroyRaw(requestParameters, initOverrides);
    }

    /**
     */
    async usersDiplomasDiplomaMediaRetrieveRaw(requestParameters: UsersDiplomasDiplomaMediaRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<NotNestedDiploma>> {
        if (requestParameters['userUuid'] == null) {
            throw new runtime.RequiredError(
                'userUuid',
                'Required parameter "userUuid" was null or undefined when calling usersDiplomasDiplomaMediaRetrieve().'
            );
        }

        if (requestParameters['uuid'] == null) {
            throw new runtime.RequiredError(
                'uuid',
                'Required parameter "uuid" was null or undefined when calling usersDiplomasDiplomaMediaRetrieve().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // qrCodeTokenAuth authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // jwtAuth authentication
        }

        const response = await this.request({
            path: `/api/users/{user_uuid}/diplomas/{uuid}/diploma-media/`.replace(`{${"user_uuid"}}`, encodeURIComponent(String(requestParameters['userUuid']))).replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters['uuid']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => NotNestedDiplomaFromJSON(jsonValue));
    }

    /**
     */
    async usersDiplomasDiplomaMediaRetrieve(requestParameters: UsersDiplomasDiplomaMediaRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<NotNestedDiploma> {
        const response = await this.usersDiplomasDiplomaMediaRetrieveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async usersDiplomasDiplomaMediaUpdateRaw(requestParameters: UsersDiplomasDiplomaMediaUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<NotNestedDiploma>> {
        if (requestParameters['userUuid'] == null) {
            throw new runtime.RequiredError(
                'userUuid',
                'Required parameter "userUuid" was null or undefined when calling usersDiplomasDiplomaMediaUpdate().'
            );
        }

        if (requestParameters['uuid'] == null) {
            throw new runtime.RequiredError(
                'uuid',
                'Required parameter "uuid" was null or undefined when calling usersDiplomasDiplomaMediaUpdate().'
            );
        }

        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling usersDiplomasDiplomaMediaUpdate().'
            );
        }

        if (requestParameters['diplomaUrl'] == null) {
            throw new runtime.RequiredError(
                'diplomaUrl',
                'Required parameter "diplomaUrl" was null or undefined when calling usersDiplomasDiplomaMediaUpdate().'
            );
        }

        if (requestParameters['diplomaType'] == null) {
            throw new runtime.RequiredError(
                'diplomaType',
                'Required parameter "diplomaType" was null or undefined when calling usersDiplomasDiplomaMediaUpdate().'
            );
        }

        if (requestParameters['issueDate'] == null) {
            throw new runtime.RequiredError(
                'issueDate',
                'Required parameter "issueDate" was null or undefined when calling usersDiplomasDiplomaMediaUpdate().'
            );
        }

        if (requestParameters['diplomaAvailable'] == null) {
            throw new runtime.RequiredError(
                'diplomaAvailable',
                'Required parameter "diplomaAvailable" was null or undefined when calling usersDiplomasDiplomaMediaUpdate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // qrCodeTokenAuth authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // jwtAuth authentication
        }

        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters['id'] != null) {
            formParams.append('id', requestParameters['id'] as any);
        }

        if (requestParameters['diplomaUrl'] != null) {
            formParams.append('diploma_url', requestParameters['diplomaUrl'] as any);
        }

        if (requestParameters['diplomaType'] != null) {
            formParams.append('diploma_type', requestParameters['diplomaType'] as any);
        }

        if (requestParameters['issueDate'] != null) {
            formParams.append('issue_date', requestParameters['issueDate'] as any);
        }

        if (requestParameters['diplomaAvailable'] != null) {
            formParams.append('diploma_available', requestParameters['diplomaAvailable'] as any);
        }

        const response = await this.request({
            path: `/api/users/{user_uuid}/diplomas/{uuid}/diploma-media/`.replace(`{${"user_uuid"}}`, encodeURIComponent(String(requestParameters['userUuid']))).replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters['uuid']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => NotNestedDiplomaFromJSON(jsonValue));
    }

    /**
     */
    async usersDiplomasDiplomaMediaUpdate(requestParameters: UsersDiplomasDiplomaMediaUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<NotNestedDiploma> {
        const response = await this.usersDiplomasDiplomaMediaUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * List diplomas of the user
     */
    async usersDiplomasListRaw(requestParameters: UsersDiplomasListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<NotNestedDiploma>>> {
        if (requestParameters['userUuid'] == null) {
            throw new runtime.RequiredError(
                'userUuid',
                'Required parameter "userUuid" was null or undefined when calling usersDiplomasList().'
            );
        }

        if (requestParameters['uuid'] == null) {
            throw new runtime.RequiredError(
                'uuid',
                'Required parameter "uuid" was null or undefined when calling usersDiplomasList().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['ordering'] != null) {
            queryParameters['ordering'] = requestParameters['ordering'];
        }

        if (requestParameters['search'] != null) {
            queryParameters['search'] = requestParameters['search'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // qrCodeTokenAuth authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // jwtAuth authentication
        }

        const response = await this.request({
            path: `/api/users/{user_uuid}/diplomas/`.replace(`{${"user_uuid"}}`, encodeURIComponent(String(requestParameters['userUuid']))).replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters['uuid']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(NotNestedDiplomaFromJSON));
    }

    /**
     * List diplomas of the user
     */
    async usersDiplomasList(requestParameters: UsersDiplomasListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<NotNestedDiploma>> {
        const response = await this.usersDiplomasListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Partially update a diploma
     */
    async usersDiplomasPartialUpdateRaw(requestParameters: UsersDiplomasPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<NotNestedDiploma>> {
        if (requestParameters['userUuid'] == null) {
            throw new runtime.RequiredError(
                'userUuid',
                'Required parameter "userUuid" was null or undefined when calling usersDiplomasPartialUpdate().'
            );
        }

        if (requestParameters['uuid'] == null) {
            throw new runtime.RequiredError(
                'uuid',
                'Required parameter "uuid" was null or undefined when calling usersDiplomasPartialUpdate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // qrCodeTokenAuth authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // jwtAuth authentication
        }

        const response = await this.request({
            path: `/api/users/{user_uuid}/diplomas/{uuid}/`.replace(`{${"user_uuid"}}`, encodeURIComponent(String(requestParameters['userUuid']))).replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters['uuid']))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: PatchedNotNestedDiplomaToJSON(requestParameters['patchedNotNestedDiploma']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => NotNestedDiplomaFromJSON(jsonValue));
    }

    /**
     * Partially update a diploma
     */
    async usersDiplomasPartialUpdate(requestParameters: UsersDiplomasPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<NotNestedDiploma> {
        const response = await this.usersDiplomasPartialUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Retrieve a diploma team from the user
     */
    async usersDiplomasRetrieveRaw(requestParameters: UsersDiplomasRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<NotNestedDiploma>> {
        if (requestParameters['userUuid'] == null) {
            throw new runtime.RequiredError(
                'userUuid',
                'Required parameter "userUuid" was null or undefined when calling usersDiplomasRetrieve().'
            );
        }

        if (requestParameters['uuid'] == null) {
            throw new runtime.RequiredError(
                'uuid',
                'Required parameter "uuid" was null or undefined when calling usersDiplomasRetrieve().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // qrCodeTokenAuth authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // jwtAuth authentication
        }

        const response = await this.request({
            path: `/api/users/{user_uuid}/diplomas/{uuid}/`.replace(`{${"user_uuid"}}`, encodeURIComponent(String(requestParameters['userUuid']))).replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters['uuid']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => NotNestedDiplomaFromJSON(jsonValue));
    }

    /**
     * Retrieve a diploma team from the user
     */
    async usersDiplomasRetrieve(requestParameters: UsersDiplomasRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<NotNestedDiploma> {
        const response = await this.usersDiplomasRetrieveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update a diploma
     */
    async usersDiplomasUpdateRaw(requestParameters: UsersDiplomasUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<NotNestedDiploma>> {
        if (requestParameters['userUuid'] == null) {
            throw new runtime.RequiredError(
                'userUuid',
                'Required parameter "userUuid" was null or undefined when calling usersDiplomasUpdate().'
            );
        }

        if (requestParameters['uuid'] == null) {
            throw new runtime.RequiredError(
                'uuid',
                'Required parameter "uuid" was null or undefined when calling usersDiplomasUpdate().'
            );
        }

        if (requestParameters['notNestedDiploma'] == null) {
            throw new runtime.RequiredError(
                'notNestedDiploma',
                'Required parameter "notNestedDiploma" was null or undefined when calling usersDiplomasUpdate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // qrCodeTokenAuth authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // jwtAuth authentication
        }

        const response = await this.request({
            path: `/api/users/{user_uuid}/diplomas/{uuid}/`.replace(`{${"user_uuid"}}`, encodeURIComponent(String(requestParameters['userUuid']))).replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters['uuid']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: NotNestedDiplomaToJSON(requestParameters['notNestedDiploma']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => NotNestedDiplomaFromJSON(jsonValue));
    }

    /**
     * Update a diploma
     */
    async usersDiplomasUpdate(requestParameters: UsersDiplomasUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<NotNestedDiploma> {
        const response = await this.usersDiplomasUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Récupère les informations sur l\'utilisateur courant
     */
    async usersMeRetrieveRaw(requestParameters: UsersMeRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SGDFUserDetails>> {
        if (requestParameters['uuid'] == null) {
            throw new runtime.RequiredError(
                'uuid',
                'Required parameter "uuid" was null or undefined when calling usersMeRetrieve().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // qrCodeTokenAuth authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // jwtAuth authentication
        }

        const response = await this.request({
            path: `/api/users/me/`.replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters['uuid']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SGDFUserDetailsFromJSON(jsonValue));
    }

    /**
     * Récupère les informations sur l\'utilisateur courant
     */
    async usersMeRetrieve(requestParameters: UsersMeRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SGDFUserDetails> {
        const response = await this.usersMeRetrieveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Supprimer l\'avatar enregistrée
     */
    async usersResetAvatarDestroyRaw(requestParameters: UsersResetAvatarDestroyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['uuid'] == null) {
            throw new runtime.RequiredError(
                'uuid',
                'Required parameter "uuid" was null or undefined when calling usersResetAvatarDestroy().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // qrCodeTokenAuth authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // jwtAuth authentication
        }

        const response = await this.request({
            path: `/api/users/{uuid}/reset-avatar/`.replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters['uuid']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Supprimer l\'avatar enregistrée
     */
    async usersResetAvatarDestroy(requestParameters: UsersResetAvatarDestroyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.usersResetAvatarDestroyRaw(requestParameters, initOverrides);
    }

    /**
     * Supprimer la signature enregistrée
     */
    async usersResetSignatureDestroyRaw(requestParameters: UsersResetSignatureDestroyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['uuid'] == null) {
            throw new runtime.RequiredError(
                'uuid',
                'Required parameter "uuid" was null or undefined when calling usersResetSignatureDestroy().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // qrCodeTokenAuth authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // jwtAuth authentication
        }

        const response = await this.request({
            path: `/api/users/{uuid}/reset-signature/`.replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters['uuid']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Supprimer la signature enregistrée
     */
    async usersResetSignatureDestroy(requestParameters: UsersResetSignatureDestroyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.usersResetSignatureDestroyRaw(requestParameters, initOverrides);
    }

    /**
     * La liste des utilisateurs
     */
    async usersRetrieveRaw(requestParameters: UsersRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ReadOnlyNotNestedUser>> {
        if (requestParameters['uuid'] == null) {
            throw new runtime.RequiredError(
                'uuid',
                'Required parameter "uuid" was null or undefined when calling usersRetrieve().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // qrCodeTokenAuth authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // jwtAuth authentication
        }

        const response = await this.request({
            path: `/api/users/{uuid}/`.replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters['uuid']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ReadOnlyNotNestedUserFromJSON(jsonValue));
    }

    /**
     * La liste des utilisateurs
     */
    async usersRetrieve(requestParameters: UsersRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ReadOnlyNotNestedUser> {
        const response = await this.usersRetrieveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * La liste des utilisateurs
     */
    async usersSignatureMediaRetrieveRaw(requestParameters: UsersSignatureMediaRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ReadOnlyNotNestedUser>> {
        if (requestParameters['uuid'] == null) {
            throw new runtime.RequiredError(
                'uuid',
                'Required parameter "uuid" was null or undefined when calling usersSignatureMediaRetrieve().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // qrCodeTokenAuth authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // jwtAuth authentication
        }

        const response = await this.request({
            path: `/api/users/{uuid}/signature-media/`.replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters['uuid']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ReadOnlyNotNestedUserFromJSON(jsonValue));
    }

    /**
     * La liste des utilisateurs
     */
    async usersSignatureMediaRetrieve(requestParameters: UsersSignatureMediaRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ReadOnlyNotNestedUser> {
        const response = await this.usersSignatureMediaRetrieveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * La liste des utilisateurs
     */
    async usersSignatureMediaUpdateRaw(requestParameters: UsersSignatureMediaUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ReadOnlyNotNestedUser>> {
        if (requestParameters['uuid'] == null) {
            throw new runtime.RequiredError(
                'uuid',
                'Required parameter "uuid" was null or undefined when calling usersSignatureMediaUpdate().'
            );
        }

        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling usersSignatureMediaUpdate().'
            );
        }

        if (requestParameters['username'] == null) {
            throw new runtime.RequiredError(
                'username',
                'Required parameter "username" was null or undefined when calling usersSignatureMediaUpdate().'
            );
        }

        if (requestParameters['gender'] == null) {
            throw new runtime.RequiredError(
                'gender',
                'Required parameter "gender" was null or undefined when calling usersSignatureMediaUpdate().'
            );
        }

        if (requestParameters['firstName'] == null) {
            throw new runtime.RequiredError(
                'firstName',
                'Required parameter "firstName" was null or undefined when calling usersSignatureMediaUpdate().'
            );
        }

        if (requestParameters['lastName'] == null) {
            throw new runtime.RequiredError(
                'lastName',
                'Required parameter "lastName" was null or undefined when calling usersSignatureMediaUpdate().'
            );
        }

        if (requestParameters['birthName'] == null) {
            throw new runtime.RequiredError(
                'birthName',
                'Required parameter "birthName" was null or undefined when calling usersSignatureMediaUpdate().'
            );
        }

        if (requestParameters['usageFirstName'] == null) {
            throw new runtime.RequiredError(
                'usageFirstName',
                'Required parameter "usageFirstName" was null or undefined when calling usersSignatureMediaUpdate().'
            );
        }

        if (requestParameters['usageLastName'] == null) {
            throw new runtime.RequiredError(
                'usageLastName',
                'Required parameter "usageLastName" was null or undefined when calling usersSignatureMediaUpdate().'
            );
        }

        if (requestParameters['phone'] == null) {
            throw new runtime.RequiredError(
                'phone',
                'Required parameter "phone" was null or undefined when calling usersSignatureMediaUpdate().'
            );
        }

        if (requestParameters['birthdate'] == null) {
            throw new runtime.RequiredError(
                'birthdate',
                'Required parameter "birthdate" was null or undefined when calling usersSignatureMediaUpdate().'
            );
        }

        if (requestParameters['email'] == null) {
            throw new runtime.RequiredError(
                'email',
                'Required parameter "email" was null or undefined when calling usersSignatureMediaUpdate().'
            );
        }

        if (requestParameters['fonction'] == null) {
            throw new runtime.RequiredError(
                'fonction',
                'Required parameter "fonction" was null or undefined when calling usersSignatureMediaUpdate().'
            );
        }

        if (requestParameters['fonctionCode'] == null) {
            throw new runtime.RequiredError(
                'fonctionCode',
                'Required parameter "fonctionCode" was null or undefined when calling usersSignatureMediaUpdate().'
            );
        }

        if (requestParameters['structure'] == null) {
            throw new runtime.RequiredError(
                'structure',
                'Required parameter "structure" was null or undefined when calling usersSignatureMediaUpdate().'
            );
        }

        if (requestParameters['diplomas'] == null) {
            throw new runtime.RequiredError(
                'diplomas',
                'Required parameter "diplomas" was null or undefined when calling usersSignatureMediaUpdate().'
            );
        }

        if (requestParameters['lastSync'] == null) {
            throw new runtime.RequiredError(
                'lastSync',
                'Required parameter "lastSync" was null or undefined when calling usersSignatureMediaUpdate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // qrCodeTokenAuth authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // jwtAuth authentication
        }

        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters['id'] != null) {
            formParams.append('id', requestParameters['id'] as any);
        }

        if (requestParameters['username'] != null) {
            formParams.append('username', requestParameters['username'] as any);
        }

        if (requestParameters['gender'] != null) {
            formParams.append('gender', requestParameters['gender'] as any);
        }

        if (requestParameters['firstName'] != null) {
            formParams.append('first_name', requestParameters['firstName'] as any);
        }

        if (requestParameters['lastName'] != null) {
            formParams.append('last_name', requestParameters['lastName'] as any);
        }

        if (requestParameters['birthName'] != null) {
            formParams.append('birth_name', requestParameters['birthName'] as any);
        }

        if (requestParameters['usageFirstName'] != null) {
            formParams.append('usage_first_name', requestParameters['usageFirstName'] as any);
        }

        if (requestParameters['usageLastName'] != null) {
            formParams.append('usage_last_name', requestParameters['usageLastName'] as any);
        }

        if (requestParameters['phone'] != null) {
            formParams.append('phone', requestParameters['phone'] as any);
        }

        if (requestParameters['birthdate'] != null) {
            formParams.append('birthdate', requestParameters['birthdate'] as any);
        }

        if (requestParameters['email'] != null) {
            formParams.append('email', requestParameters['email'] as any);
        }

        if (requestParameters['fonction'] != null) {
            formParams.append('fonction', requestParameters['fonction'] as any);
        }

        if (requestParameters['fonctionCode'] != null) {
            formParams.append('fonction_code', requestParameters['fonctionCode'] as any);
        }

        if (requestParameters['structure'] != null) {
            formParams.append('structure', requestParameters['structure'] as any);
        }

        if (requestParameters['diplomas'] != null) {
            formParams.append('diplomas', requestParameters['diplomas']!.join(runtime.COLLECTION_FORMATS["csv"]));
        }

        if (requestParameters['lastSync'] != null) {
            formParams.append('last_sync', (requestParameters['lastSync'] as any).toISOString());
        }

        const response = await this.request({
            path: `/api/users/{uuid}/signature-media/`.replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters['uuid']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ReadOnlyNotNestedUserFromJSON(jsonValue));
    }

    /**
     * La liste des utilisateurs
     */
    async usersSignatureMediaUpdate(requestParameters: UsersSignatureMediaUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ReadOnlyNotNestedUser> {
        const response = await this.usersSignatureMediaUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
