export const appHead = {"meta":[{"name":"viewport","content":"width=device-width, initial-scale=1, maximum-scale=1.0, user-scalable=0, virtual-keyboard=overlays-content"},{"charset":"utf-8"}],"link":[{"rel":"icon","type":"image/png","sizes":"16x16","href":"/assets/icons/favicon-16.png"},{"rel":"icon","type":"image/png","sizes":"32x32","href":"/assets/icons/favicon-32.png"},{"rel":"icon","type":"image/png","sizes":"48x48","href":"/assets/icons/favicon-48.png"},{"rel":"icon","type":"image/png","sizes":"96x96","href":"/assets/icons/favicon-96.png"},{"rel":"icon","type":"image/png","sizes":"128x128","href":"/assets/icons/favicon-128.png"},{"rel":"icon","type":"image/png","sizes":"192x192","href":"/assets/icons/favicon-192.png"},{"rel":"apple-touch-icon","type":"image/png","sizes":"167x167","href":"/assets/icons/favicon-167.png"},{"rel":"apple-touch-icon","type":"image/png","sizes":"180x180","href":"/assets/icons/favicon-180.png"}],"style":[],"script":[],"noscript":[],"charset":"utf-8","viewport":"width=device-width, initial-scale=1, maximum-scale=1.0, user-scalable=0, virtual-keyboard=overlays-content"}

export const appBaseURL = "/"

export const appBuildAssetsDir = "/_nuxt/"

export const appCdnURL = ""

export const appLayoutTransition = false

export const appPageTransition = false

export const appViewTransition = false

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"

export const appRootAttrs = {"id":"__nuxt"}

export const appTeleportTag = "div"

export const appTeleportId = "teleports"

export const appTeleportAttrs = {"id":"teleports"}

export const appSpaLoaderTag = "div"

export const appSpaLoaderAttrs = {"id":"__nuxt-loader"}

export const renderJsonPayloads = true

export const componentIslands = false

export const payloadExtraction = true

export const cookieStore = true

export const appManifest = true

export const remoteComponentIslands = false

export const selectiveClient = false

export const devPagesDir = null

export const devRootDir = null

export const devLogs = false

export const nuxtLinkDefaults = {"componentName":"NuxtLink","prefetch":true,"prefetchOn":{"visibility":true}}

export const asyncDataDefaults = {"value":null,"errorValue":null,"deep":true}

export const resetAsyncDataToUndefined = true

export const nuxtDefaultErrorValue = null

export const fetchDefaults = {}

export const vueAppRootContainer = '#__nuxt'

export const viewTransition = false

export const appId = "nuxt-app"

export const outdatedBuildInterval = 3600000

export const multiApp = false

export const chunkErrorEvent = "vite:preloadError"

export const crawlLinks = false

export const spaLoadingTemplateOutside = false