
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    if (!router) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    for (const route of mod.default || mod) {
      router.addRoute(route)
    }
    router.replace('')
  })
}

export function handleHotUpdate(_router) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
  }
}
import { default as completeRhzT3nJz4jMeta } from "/app/webapp/pages/auth/complete.vue?macro=true";
import { default as intrassocasyhMQbdbdMeta } from "/app/webapp/pages/auth/intrassoc.vue?macro=true";
import { default as resetaobnRrZeh0Meta } from "/app/webapp/pages/auth/password/reset.vue?macro=true";
import { default as deontologiekfxODYCWSNMeta } from "/app/webapp/pages/deontologie.vue?macro=true";
import { default as indexQ2teV6cG24Meta } from "/app/webapp/pages/gestionnaire/[meetingId]/index.vue?macro=true";
import { default as indexqkJBIcmwypMeta } from "/app/webapp/pages/gestionnaire/index.vue?macro=true";
import { default as my_45scopem8Yb7wzK4RMeta } from "/app/webapp/pages/gestionnaire/my-scope.vue?macro=true";
import { default as opinion_45reviewUQMLqCkQtyMeta } from "/app/webapp/pages/gestionnaire/opinion-review.vue?macro=true";
import { default as participants_45exportGxAEon3nHuMeta } from "/app/webapp/pages/gestionnaire/participants-export.vue?macro=true";
import { default as index1YCNhmZamnMeta } from "/app/webapp/pages/index.vue?macro=true";
import { default as loginYX922PwNPgMeta } from "/app/webapp/pages/login.vue?macro=true";
import { default as mentionsN3alzi0oknMeta } from "/app/webapp/pages/mentions.vue?macro=true";
import { default as mes_45infosvxX0BYWrmXMeta } from "/app/webapp/pages/mes-infos.vue?macro=true";
import { default as politique_45confidentialite71Yzd0M5vLMeta } from "/app/webapp/pages/politique-confidentialite.vue?macro=true";
import { default as documentsxqy7ZKWiNAMeta } from "/app/webapp/pages/stages/[meetingId]/documents.vue?macro=true";
import { default as _91dailyTaskTeamId_93Akniq1lq8zMeta } from "/app/webapp/pages/stages/[meetingId]/equipes-de-vie/[dailyTaskTeamId].vue?macro=true";
import { default as _91teamId_93y7o2H2xadFMeta } from "/app/webapp/pages/stages/[meetingId]/equipes/[teamId].vue?macro=true";
import { default as indexL6q5wzctRqMeta } from "/app/webapp/pages/stages/[meetingId]/equipes/index.vue?macro=true";
import { default as indexHN39kRWZD8Meta } from "/app/webapp/pages/stages/[meetingId]/formulaires/[formId]/index.vue?macro=true";
import { default as reponseNH8BTE2WxGMeta } from "/app/webapp/pages/stages/[meetingId]/formulaires/[formId]/reponse.vue?macro=true";
import { default as indexMCTIuISbe5Meta } from "/app/webapp/pages/stages/[meetingId]/formulaires/index.vue?macro=true";
import { default as indexGUSILdz9YcMeta } from "/app/webapp/pages/stages/[meetingId]/index.vue?macro=true";
import { default as mes_45infosS1KDT1C3d5Meta } from "/app/webapp/pages/stages/[meetingId]/mes-infos.vue?macro=true";
import { default as exportFUtLRqhuAWMeta } from "/app/webapp/pages/stages/[meetingId]/notes/[noteId]/export.vue?macro=true";
import { default as indexJsBjFysGt6Meta } from "/app/webapp/pages/stages/[meetingId]/notes/index.vue?macro=true";
import { default as documentspFXnern4iNMeta } from "/app/webapp/pages/stages/[meetingId]/parametrage/documents.vue?macro=true";
import { default as equipesadTRILdZ5ZMeta } from "/app/webapp/pages/stages/[meetingId]/parametrage/equipes.vue?macro=true";
import { default as exportsA4wLQKIF2SMeta } from "/app/webapp/pages/stages/[meetingId]/parametrage/exports.vue?macro=true";
import { default as formateursWI4MjnQWuCMeta } from "/app/webapp/pages/stages/[meetingId]/parametrage/formateurs.vue?macro=true";
import { default as apercu36fuLm8F8XMeta } from "/app/webapp/pages/stages/[meetingId]/parametrage/formulaires/[formId]/apercu.vue?macro=true";
import { default as indexnfuivBzg6YMeta } from "/app/webapp/pages/stages/[meetingId]/parametrage/formulaires/[formId]/index.vue?macro=true";
import { default as reponsesoTk5xXavChMeta } from "/app/webapp/pages/stages/[meetingId]/parametrage/formulaires/[formId]/reponses.vue?macro=true";
import { default as indexfJsspuJ0smMeta } from "/app/webapp/pages/stages/[meetingId]/parametrage/formulaires/index.vue?macro=true";
import { default as indexB6rJFOpR1jMeta } from "/app/webapp/pages/stages/[meetingId]/parametrage/index.vue?macro=true";
import { default as opinion_45reviewck2Eru9TGPMeta } from "/app/webapp/pages/stages/[meetingId]/parametrage/opinion-review.vue?macro=true";
import { default as participantsjqeK5dU1hiMeta } from "/app/webapp/pages/stages/[meetingId]/parametrage/participants.vue?macro=true";
import { default as planning_45creationMZj1pssvCyMeta } from "/app/webapp/pages/stages/[meetingId]/parametrage/planning-creation.vue?macro=true";
import { default as planningIEHQCOnOOXMeta } from "/app/webapp/pages/stages/[meetingId]/parametrage/planning.vue?macro=true";
import { default as sallesZtw9061owgMeta } from "/app/webapp/pages/stages/[meetingId]/parametrage/salles.vue?macro=true";
import { default as servicesOybSXW1Z8PMeta } from "/app/webapp/pages/stages/[meetingId]/parametrage/services.vue?macro=true";
import { default as tagada8xtDeW0lngMeta } from "/app/webapp/pages/stages/[meetingId]/parametrage/tagada.vue?macro=true";
import { default as participantSdq9C3ZLDpMeta } from "/app/webapp/pages/stages/[meetingId]/participant.vue?macro=true";
import { default as check_45inuScKYLgQ3MMeta } from "/app/webapp/pages/stages/[meetingId]/participants/[participantId]/check-in.vue?macro=true";
import { default as indexkwPZujsnkiMeta } from "/app/webapp/pages/stages/[meetingId]/participants/index.vue?macro=true";
import { default as planning_45formateursu4JNvt2EF4Meta } from "/app/webapp/pages/stages/[meetingId]/planning-formateurs.vue?macro=true";
import { default as planning_45impressionZ0cBUOl55nMeta } from "/app/webapp/pages/stages/[meetingId]/planning-impression.vue?macro=true";
import { default as planningnkiBnGVS19Meta } from "/app/webapp/pages/stages/[meetingId]/planning.vue?macro=true";
import { default as servicesB129eJa9rwMeta } from "/app/webapp/pages/stages/[meetingId]/services.vue?macro=true";
export default [
  {
    name: "auth-complete",
    path: "/auth/complete",
    component: () => import("/app/webapp/pages/auth/complete.vue")
  },
  {
    name: "auth-intrassoc",
    path: "/auth/intrassoc",
    component: () => import("/app/webapp/pages/auth/intrassoc.vue")
  },
  {
    name: "auth-password-reset",
    path: "/auth/password/reset",
    component: () => import("/app/webapp/pages/auth/password/reset.vue")
  },
  {
    name: "deontologie",
    path: "/deontologie",
    component: () => import("/app/webapp/pages/deontologie.vue")
  },
  {
    name: "gestionnaire-meetingId",
    path: "/gestionnaire/:meetingId()",
    component: () => import("/app/webapp/pages/gestionnaire/[meetingId]/index.vue")
  },
  {
    name: "gestionnaire",
    path: "/gestionnaire",
    component: () => import("/app/webapp/pages/gestionnaire/index.vue")
  },
  {
    name: "gestionnaire-my-scope",
    path: "/gestionnaire/my-scope",
    component: () => import("/app/webapp/pages/gestionnaire/my-scope.vue")
  },
  {
    name: "gestionnaire-opinion-review",
    path: "/gestionnaire/opinion-review",
    component: () => import("/app/webapp/pages/gestionnaire/opinion-review.vue")
  },
  {
    name: "gestionnaire-participants-export",
    path: "/gestionnaire/participants-export",
    component: () => import("/app/webapp/pages/gestionnaire/participants-export.vue")
  },
  {
    name: "index",
    path: "/",
    meta: index1YCNhmZamnMeta || {},
    component: () => import("/app/webapp/pages/index.vue")
  },
  {
    name: "login",
    path: "/login",
    component: () => import("/app/webapp/pages/login.vue")
  },
  {
    name: "mentions",
    path: "/mentions",
    component: () => import("/app/webapp/pages/mentions.vue")
  },
  {
    name: "mes-infos",
    path: "/mes-infos",
    component: () => import("/app/webapp/pages/mes-infos.vue")
  },
  {
    name: "politique-confidentialite",
    path: "/politique-confidentialite",
    component: () => import("/app/webapp/pages/politique-confidentialite.vue")
  },
  {
    name: "stages-meetingId-documents",
    path: "/stages/:meetingId()/documents",
    component: () => import("/app/webapp/pages/stages/[meetingId]/documents.vue")
  },
  {
    name: "stages-meetingId-equipes-de-vie-dailyTaskTeamId",
    path: "/stages/:meetingId()/equipes-de-vie/:dailyTaskTeamId()",
    component: () => import("/app/webapp/pages/stages/[meetingId]/equipes-de-vie/[dailyTaskTeamId].vue")
  },
  {
    name: "stages-meetingId-equipes-teamId",
    path: "/stages/:meetingId()/equipes/:teamId()",
    component: () => import("/app/webapp/pages/stages/[meetingId]/equipes/[teamId].vue")
  },
  {
    name: "stages-meetingId-equipes",
    path: "/stages/:meetingId()/equipes",
    component: () => import("/app/webapp/pages/stages/[meetingId]/equipes/index.vue")
  },
  {
    name: "stages-meetingId-formulaires-formId",
    path: "/stages/:meetingId()/formulaires/:formId()",
    component: () => import("/app/webapp/pages/stages/[meetingId]/formulaires/[formId]/index.vue")
  },
  {
    name: "stages-meetingId-formulaires-formId-reponse",
    path: "/stages/:meetingId()/formulaires/:formId()/reponse",
    component: () => import("/app/webapp/pages/stages/[meetingId]/formulaires/[formId]/reponse.vue")
  },
  {
    name: "stages-meetingId-formulaires",
    path: "/stages/:meetingId()/formulaires",
    component: () => import("/app/webapp/pages/stages/[meetingId]/formulaires/index.vue")
  },
  {
    name: "stages-meetingId",
    path: "/stages/:meetingId()",
    component: () => import("/app/webapp/pages/stages/[meetingId]/index.vue")
  },
  {
    name: "stages-meetingId-mes-infos",
    path: "/stages/:meetingId()/mes-infos",
    component: () => import("/app/webapp/pages/stages/[meetingId]/mes-infos.vue")
  },
  {
    name: "stages-meetingId-notes-noteId-export",
    path: "/stages/:meetingId()/notes/:noteId()/export",
    component: () => import("/app/webapp/pages/stages/[meetingId]/notes/[noteId]/export.vue")
  },
  {
    name: "stages-meetingId-notes",
    path: "/stages/:meetingId()/notes",
    component: () => import("/app/webapp/pages/stages/[meetingId]/notes/index.vue")
  },
  {
    name: "stages-meetingId-parametrage-documents",
    path: "/stages/:meetingId()/parametrage/documents",
    component: () => import("/app/webapp/pages/stages/[meetingId]/parametrage/documents.vue")
  },
  {
    name: "stages-meetingId-parametrage-equipes",
    path: "/stages/:meetingId()/parametrage/equipes",
    component: () => import("/app/webapp/pages/stages/[meetingId]/parametrage/equipes.vue")
  },
  {
    name: "stages-meetingId-parametrage-exports",
    path: "/stages/:meetingId()/parametrage/exports",
    component: () => import("/app/webapp/pages/stages/[meetingId]/parametrage/exports.vue")
  },
  {
    name: "stages-meetingId-parametrage-formateurs",
    path: "/stages/:meetingId()/parametrage/formateurs",
    component: () => import("/app/webapp/pages/stages/[meetingId]/parametrage/formateurs.vue")
  },
  {
    name: "stages-meetingId-parametrage-formulaires-formId-apercu",
    path: "/stages/:meetingId()/parametrage/formulaires/:formId()/apercu",
    component: () => import("/app/webapp/pages/stages/[meetingId]/parametrage/formulaires/[formId]/apercu.vue")
  },
  {
    name: "stages-meetingId-parametrage-formulaires-formId",
    path: "/stages/:meetingId()/parametrage/formulaires/:formId()",
    component: () => import("/app/webapp/pages/stages/[meetingId]/parametrage/formulaires/[formId]/index.vue")
  },
  {
    name: "stages-meetingId-parametrage-formulaires-formId-reponses",
    path: "/stages/:meetingId()/parametrage/formulaires/:formId()/reponses",
    component: () => import("/app/webapp/pages/stages/[meetingId]/parametrage/formulaires/[formId]/reponses.vue")
  },
  {
    name: "stages-meetingId-parametrage-formulaires",
    path: "/stages/:meetingId()/parametrage/formulaires",
    component: () => import("/app/webapp/pages/stages/[meetingId]/parametrage/formulaires/index.vue")
  },
  {
    name: "stages-meetingId-parametrage",
    path: "/stages/:meetingId()/parametrage",
    component: () => import("/app/webapp/pages/stages/[meetingId]/parametrage/index.vue")
  },
  {
    name: "stages-meetingId-parametrage-opinion-review",
    path: "/stages/:meetingId()/parametrage/opinion-review",
    component: () => import("/app/webapp/pages/stages/[meetingId]/parametrage/opinion-review.vue")
  },
  {
    name: "stages-meetingId-parametrage-participants",
    path: "/stages/:meetingId()/parametrage/participants",
    component: () => import("/app/webapp/pages/stages/[meetingId]/parametrage/participants.vue")
  },
  {
    name: "stages-meetingId-parametrage-planning-creation",
    path: "/stages/:meetingId()/parametrage/planning-creation",
    component: () => import("/app/webapp/pages/stages/[meetingId]/parametrage/planning-creation.vue")
  },
  {
    name: "stages-meetingId-parametrage-planning",
    path: "/stages/:meetingId()/parametrage/planning",
    component: () => import("/app/webapp/pages/stages/[meetingId]/parametrage/planning.vue")
  },
  {
    name: "stages-meetingId-parametrage-salles",
    path: "/stages/:meetingId()/parametrage/salles",
    component: () => import("/app/webapp/pages/stages/[meetingId]/parametrage/salles.vue")
  },
  {
    name: "stages-meetingId-parametrage-services",
    path: "/stages/:meetingId()/parametrage/services",
    component: () => import("/app/webapp/pages/stages/[meetingId]/parametrage/services.vue")
  },
  {
    name: "stages-meetingId-parametrage-tagada",
    path: "/stages/:meetingId()/parametrage/tagada",
    component: () => import("/app/webapp/pages/stages/[meetingId]/parametrage/tagada.vue")
  },
  {
    name: "stages-meetingId-participant",
    path: "/stages/:meetingId()/participant",
    component: () => import("/app/webapp/pages/stages/[meetingId]/participant.vue")
  },
  {
    name: "stages-meetingId-participants-participantId-check-in",
    path: "/stages/:meetingId()/participants/:participantId()/check-in",
    component: () => import("/app/webapp/pages/stages/[meetingId]/participants/[participantId]/check-in.vue")
  },
  {
    name: "stages-meetingId-participants",
    path: "/stages/:meetingId()/participants",
    component: () => import("/app/webapp/pages/stages/[meetingId]/participants/index.vue")
  },
  {
    name: "stages-meetingId-planning-formateurs",
    path: "/stages/:meetingId()/planning-formateurs",
    component: () => import("/app/webapp/pages/stages/[meetingId]/planning-formateurs.vue")
  },
  {
    name: "stages-meetingId-planning-impression",
    path: "/stages/:meetingId()/planning-impression",
    component: () => import("/app/webapp/pages/stages/[meetingId]/planning-impression.vue")
  },
  {
    name: "stages-meetingId-planning",
    path: "/stages/:meetingId()/planning",
    component: () => import("/app/webapp/pages/stages/[meetingId]/planning.vue")
  },
  {
    name: "stages-meetingId-services",
    path: "/stages/:meetingId()/services",
    component: () => import("/app/webapp/pages/stages/[meetingId]/services.vue")
  }
]